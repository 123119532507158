import { PositionStatus } from './features/positions-shared/types';

export { default as HierarchyLocationIcon } from '@mui/icons-material/LocationOn';
export { default as HierarchyOrgUnitIcon } from '@mui/icons-material/CorporateFare';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const EXPECTED_CODE_LENGTH = 6;

export const WORKING_HOURS_IN_A_DAY = 8;

export const DEBOUNCING_DELAY = 200;

// Date Fns formats
export const DATEFNS_FORMATS_UI_DATE = 'dd MMM yyyy';
export const DATEFNS_FORMATS_UI_TIME = 'hh:mm aa';
export const DATEFNS_FORMATS_UI_INPUT_DATE = 'dd/MM/yyyy';
export const DATEFNS_FORMATS_UI_DATE_TIME = 'dd MMM yyyy, HH:mm:ss';
export const DATEFNS_FORMATS_API_DATE = 'yyyy-MM-dd';
export const DATEFNS_FORMATS_API_DATE_TIME = 'yyyy-MM-dd\'T\'HH:mm:ss';
export const DATEFNS_FORMATS_UI_WEEK_DATE  = 'EEE d MMM yyyy';
export const DATEFNS_FORMATS_UI_FULLWEEK_DATE  = 'EEEE, d MMM yyyy';
export const DATEFNS_FORMATS_UI_FULL_DATE_TIME = "dd MMMM yyyy 'at' p";
export const DATEFNS_FORMATS_UI_YESTERDAY_TIME = "'Yesterday at' p";

// Moment formats
export const FORMATS_UI_DATE = 'DD MMM yyyy';
export const FORMATS_UI_INPUT_DATE = 'DD/MM/yyyy';
export const FORMATS_API_DATE = 'YYYY-MM-DD';
export const FORMATS_UI_DATE_TIME = 'DD MMM yyyy, HH:mm:ss';
export const FORMATS_UI_CHART_DATE = 'MMM YYYY';
export const FORMATS_API_CHART_DATE = 'YYYY-MM';
export const FORMATS_UI_LONG_DATE = `dddd, ${FORMATS_UI_DATE}`;

export const FORMATS_UI_INTEGER = '0,0';
export const FORMATS_UI_FLOAT = '0,0.[00]';
export const FORMATS_UI_PERCENT = '0,0.00%';
export const FORMATS_UI_CURRENCY_WITH_CENTS = '0,0.00';
export const FORMATS_UI_CURRENCY_WITHOUT_CENTS = '0,0';

export const FILE_MAX_UPLOAD_SIZE_IN_BYTES = 5 * 1024 * 1024; // 5Mb

export const SIMPLEXITY_TREE_VIEW_ROOT_BUTTON_WIDTH = 48;
export const SIMPLEXITY_TREE_VIEW_ROOT_BUTTON_WIDTH_WITH_PX = `${SIMPLEXITY_TREE_VIEW_ROOT_BUTTON_WIDTH}px`;
export const SIMPLEXITY_TREE_VIEW_ROOT_BUTTON_HEIGHT = 44;
export const SIMPLEXITY_TREE_VIEW_ROOT_BUTTON_HEIGHT_WITH_PX = `${SIMPLEXITY_TREE_VIEW_ROOT_BUTTON_HEIGHT}px`;
export const SIMPLEXITY_TREE_VIEW_BUTTON_WIDTH = 52;
export const SIMPLEXITY_TREE_VIEW_BUTTON_WIDTH_WITH_PX = `${SIMPLEXITY_TREE_VIEW_BUTTON_WIDTH}px`;
export const SIMPLEXITY_TREE_VIEW_BUTTON_HEIGHT = 60;
export const SIMPLEXITY_TREE_VIEW_BUTTON_HEIGHT_WITH_PX = `${SIMPLEXITY_TREE_VIEW_BUTTON_HEIGHT}px`;

export const LOCATIONS_MAX_DEPTH = 10;
export const ORGUNITS_MAX_DEPTH = 10;

export const TEXT_TYPES = ['plain', 'markdown'] as const;
export const COUNTRY_CODES = ['AU', 'NZ'] as const;
export const FEE_RATE_TYPES = ['absolute', 'percent'] as const;
export const FEE_RATE_UNITS = ['perDay', 'perHour'] as const;
export const WORKER_RATE_UNITS = ['perDay', 'perHour', 'perMonth', 'perWeek'] as const;
export const WORK_SCHEDULE_PERIODS = ['perWeek'] as const;
export const WORK_SCHEDULE_TYPES = ['fullTime', 'partTime'] as const;
export const WORK_SCHEDULE_UNITS = ['days', 'hours'] as const;
export const ENGAGEMENT_TYPES = ['llc', 'paye', 'payg', 'ptyLtd', 'soleTrader'] as const;
export const ORDER_DIRECTIONS = ['ascending', 'descending'] as const;
export const REVIEW_CONSENSUSES = ['approve', 'reject'] as const;
export const COLLABORATOR_POSITION_ACCESS_LEVELS = ['reader', 'writer'] as const;
export const COLLABORATOR_APPROVER_ACCESS_LEVELS = ['none', 'approver'] as const;
export const TENANT_PERMISSIONS = [
  'tenantAdmin',
  'positionUser',
  'positionCreate',
  'positionReview',
  'positionSend',
  'placementUser',
  'placementCreate',
  'contractUser',
  'timesheetUser',
  'timesheetReview',
  'timesheetQuickReview',
  'timesheetFinance',
  'expenseUser',
  'expenseReview',
  'reportUser',
  'reportCreate',
] as const;
export const AGENCY_PERMISSIONS = [
  'administrationUser',
  'contractsUser',
  'reportsUser',
  'reportsCreate',
] as const;

export const ORDER_BY_OPTIONS = {
  ascending: 'asc',
  descending: 'desc',
} as const;

export const POSITION_STATUS_OPTIONS: Record<PositionStatus, string> = {
  draft: 'Draft',
  inReview: 'In Review',
  approved: 'Approved',
  rejected: 'Rejected',
  sourcing: 'Sourcing',
  filled: 'Filled',
  cancelled: 'Cancelled'
} as const;

export const POSITION_STATUSES = Object.keys(
  POSITION_STATUS_OPTIONS
) as ReadonlyArray<keyof typeof POSITION_STATUS_OPTIONS>;
