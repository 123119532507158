import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { X } from 'react-feather';
import { useSelector } from '../../hooks';
import { TrackedIconButton } from '@oncore/shared';

const HolidayContainerStyle = {
  backgroundColor: '#fffcb3',
  paddingTop: '20px',
  paddingBottom: '10px',
  paddingRight: '5px',
  paddingLeft: '5px',
  display: 'flex',
  justifyContent: 'space-between'
};

const TypographyStyle =  {
  fontFamily: 'kb-article, sans-serif',
  color: 'black ',
  textAlign: 'center',
  margin: '0 auto',
  fontSize: '14px'
};

const BANNER_DISMISSED_KEY = 'holidayBannerDismissed';

const HolidayBanner = () => {
  const { holidayBanner } = useSelector((s) => s.app);
  const [isDismissed, setIsDismissed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getDismissedStateFromLocalStorage = () => {
    const dismissedState = localStorage.getItem(BANNER_DISMISSED_KEY);
    setIsLoading(false);
    return dismissedState ? JSON.parse(dismissedState) : false;
  };
  const displayHolidayBannerBasedOnUser = useSelector((s) => {
    const context = s.app.currentContext.context;
    return context === 'enterprise' || context === 'user';
  });

  useEffect(() => {
    setIsDismissed(getDismissedStateFromLocalStorage());
  });

  const setDismissedStateFromLocalStorage = (dismissed: boolean) => {
    localStorage.setItem(BANNER_DISMISSED_KEY, JSON.stringify(dismissed));
  };

  const dismissBanner = () => {
    setIsDismissed(true);
    setDismissedStateFromLocalStorage(true);
  };

  const displayBanner = () => {
    const startDateString = holidayBanner?.startDate;
    const endDateString = holidayBanner?.endDate;
    if (!startDateString || !endDateString) {
      return false;
    }

    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return (
      currentDate <= endDate &&
      currentDate >= startDate &&
      !isDismissed && displayHolidayBannerBasedOnUser
    );
  };

  return displayBanner() && !isLoading ? (
    <div style={HolidayContainerStyle}>
      <Typography
        sx={TypographyStyle}
        dangerouslySetInnerHTML={{ __html: holidayBanner?.message || '' }}
      />
      <TrackedIconButton
        onClick={dismissBanner}
        size={'small'}
        trackedName="Dismiss banner"
      >
        <X />
      </TrackedIconButton>
    </div>
  ) : null;
};

export default HolidayBanner;