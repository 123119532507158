import { useSelector } from 'react-redux';
import { FunctionComponent } from 'react';

import { getAppStateSelector } from 'src/store/selectors';

export type UntitledUIRendererProps = {
  untitledUI: FunctionComponent;
  legacy: FunctionComponent;
};

const UntitledUIRenderer = (props: UntitledUIRendererProps) => {

  const { isUntitledUI } = useSelector(getAppStateSelector());

  if (isUntitledUI) {
    return <props.untitledUI/>;
  }

  return <props.legacy/>;
};

export default UntitledUIRenderer;
