import React, { PropsWithChildren } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import AuthGuard from './AuthGuard';
import { useSelector } from 'react-redux';
import { getEnvironmentSelector } from 'src/state/environment/selectors';

type AuthProviderProps = PropsWithChildren<{
  loading?: React.ReactNode;
}>;

export const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;
  const { auth } = useSelector(getEnvironmentSelector());

  return (
    <Auth0Provider
      domain={auth.domain}
      clientId={auth.clientID}
      authorizationParams={{
        scope: 'openid profile general',
        audience: auth.audience,
        redirect_uri: window.location.origin
      }}
    >
      <AuthGuard>
        {children}
      </AuthGuard>
    </Auth0Provider>
  );
};

export default AuthProvider;
