import { ThemeContextProvider, ThemeConfig } from '@oncore/shared';
import useSettings from '../../hooks/useSettings';
import { useSelector } from 'react-redux';
import { getAppStateSelector } from 'src/store/selectors';


export type Props = {
  children?: React.ReactNode;
};

export const OncoreFlexThemeProvider: React.FC<Props> = (props) => {
  const {
    children
  } = props;

  const { settings } = useSettings();

  const { isUntitledUI } = useSelector(getAppStateSelector());

  const config: ThemeConfig = {
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    isUntitledUI: isUntitledUI,
  };

  return (
    <ThemeContextProvider config={config}>
      {children}
    </ThemeContextProvider>
  );
};

export default OncoreFlexThemeProvider;
