import React, { Reducer, useEffect, useMemo, useReducer, useRef } from 'react';
import { useSubscription } from 'relay-hooks';
import { Bell } from 'react-feather';
import { Box, Badge, Menu } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { useDispatch } from 'src/hooks';
import { setMenuOpen } from 'src/store/appSlice';
import {
  Action,
  Store,
  initialState,
  reducer
} from './reducer';
import {
  NotificationsSubscription,
  notificationsSubscription
} from './relay';
import Content from './components/Dropdown';
import Header from './components/Header';
import { TrackedIconButton } from '@oncore/shared';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const styles = {
  menu: (theme: Theme) => ({
    '& > .MuiPaper-root': {
      overflow: 'hidden',
      '& > .MuiList-root': {
        height: '100%',
      },
      [theme.breakpoints.down('md')]: {
        width: '100vw',
        height: 'calc(100vh - 56px)',
        maxWidth: '100vw',
        maxHeight: '100vh',
        top: '56px !important',
        left: '0 !important',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        '& ul': {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }
      },
      [theme.breakpoints.up('md')]: {
        width: 480,
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        '& ul': {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }
      }
    }
  }),
};

export type Props = {
  children?: never;
};

const Notifications: React.FC<Props> = () => {
  const appDispatch = useDispatch();

  const [state, dispatch] = useReducer<Reducer<Store, Action>>(reducer, initialState);

  const ref = useRef<HTMLButtonElement>(null);

  const mounted = new AbortController();

  useEffect(() => () => mounted.abort(), []);

  // This is super-important! If it is not CONST, but used directly in useSubscription,
  // the subscription will be re-subscribed on every re-render.
  const config = useMemo(() => ({
    variables: { },
    subscription: notificationsSubscription,
    onNext: (x: NotificationsSubscription['response'] | null | undefined) => {
      if (!mounted.signal.aborted && x) {
        dispatch({ type: 'RECEIVE_ITEM', payload: x.notification });
      }
    },
    onError: (x: Error) => console.error('ERROR', x),
    onCompleted: () => console.log('COMPLETED')
  }), []);

  useSubscription<NotificationsSubscription>(config);

  // useEffect(() => {
  //   // navigator.serviceWorker.getRegistrations().then(x => alert(x));
  //   // navigator.serviceWorker.ready.then(rego => console.log('REGO', rego));
  //   Notification
  //     .requestPermission()
  //     .then((value) => {
  //       if (!mounted.signal.aborted) {
  //         // alert(value);
  //         dispatch({ type: 'UPDATE_PERMISSION', payload: value });
  //       }
  //     });
  // }, []);

  return (
    <Box>
      <TrackedIconButton
        trackedName="Notifications"
        ref={ref}
        onClick={() => {
          appDispatch(setMenuOpen(false));
          dispatch({ type: 'OPEN_DROPDOWN' });
        }}
        sx={{ color: 'white' }}
        color="info"
        size="large"
      >
        {state.attn ? (
          <StyledBadge
            variant="dot"
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Bell />
          </StyledBadge>
        ) : (<Bell />)}
      </TrackedIconButton>
      <Menu
        onClose={() => dispatch({ type: 'CLOSE_DROPDOWN' })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        anchorEl={ref.current}
        open={state.opened}
        sx={styles.menu}
      >
        <Header
          state={state}
          dispatch={dispatch}
        />
        <Content
          state={state}
          dispatch={dispatch}
        />
      </Menu>
    </Box>
  );
};

export default Notifications;
