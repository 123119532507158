import { j as a } from "./jsx-runtime-B6kdoens.js";
import l from "styled-components";
import { forwardRef as d } from "react";
import "./components/Theme/themes.es.js";
import "./palettes-C9VCECeM.js";
import { getRadii as m, getPaletteForeground as c } from "./components/Theme/utils.es.js";
import "./CssBaseline-CQMfs2gH.js";
import { variantStyles as u, sizeStyles as p } from "./components/IconButton/styles.es.js";
const f = l.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  border-radius: ${m("full")};
  
  ${(t) => u[t.$variant]}
  ${(t) => p[t.$size]}
  
  &:disabled {
    cursor: not-allowed;
    
    color: ${c("disabled.default")};
  }
`, B = d(function(o, r) {
  const {
    variant: e = "contained",
    color: i = "brand",
    size: n = "md",
    ...s
  } = o;
  return /* @__PURE__ */ a.jsx(
    f,
    {
      $variant: e,
      $color: i,
      $size: n,
      ref: r,
      ...s
    }
  );
});
export {
  B as I
};
