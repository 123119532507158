import { createContext as t, useContext as g } from "react";
import { originalConsoleError as c, originalConsoleWarn as i, originalConsoleInfo as l } from "./shared/Logging/utils.es.js";
const b = t({
  log: (n, a, e) => {
    const o = e ? Object.keys(e).reduce((s, r) => s.replace(new RegExp(`{${r}}`, "g"), JSON.stringify(e[r]))) : a;
    switch (n) {
      case "Verbose":
      case "Debug":
      case "Information":
        l(o);
        break;
      case "Warning":
        i(o);
        break;
      case "Error":
      case "Fatal":
        c(o);
        break;
    }
  }
}), f = () => g(b);
export {
  b as L,
  f as u
};
