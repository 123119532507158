import { useSelector } from 'react-redux';

import TopBar from '@oncore/ui/TopBar';
import ChromeDropdownItems from './ChromeDropdownItems';
import ChromeLeftAlignedTopBarItems from './ChromeLeftAlignedTopBarItems';
import ChromeRightAlignedTopBarItems from './ChromeRightAlignedTopBarItems';
import { getAppStateSelector } from 'src/store/selectors';
import { useChromeProductName } from './hooks';

const ChromeTopBar = () => {
  const {
    displayName,
    emailAddress
  } = useSelector(getAppStateSelector());
  const logo = useChromeProductName();

  return (
    <TopBar
      product={logo}
      userLoginEmail={emailAddress}
      userDisplayName={displayName}
      dropdownChildren={(<ChromeDropdownItems />)}
      leftAlignedChildren={(<ChromeLeftAlignedTopBarItems />)}
      rightAlignedChildren={(<ChromeRightAlignedTopBarItems />)}
    />
  );
};

export default ChromeTopBar;
