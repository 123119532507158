import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { getAppStateSelector } from 'src/store/selectors';
import { SideBarProps } from '@oncore/ui/SideBar';

export const useChromeProductName = () => {
  const { currentContext } = useSelector(getAppStateSelector());
  return useMemo<SideBarProps['logo']>(() => {
    switch (currentContext.context) {
      case 'user':
        return 'freedom';
      case 'agency':
        return 'connect';
      case 'enterprise':
        return 'simplexity';
      default:
        return 'oncoreflex';
    }
  }, [currentContext.context]);
};
