import { j as r } from "./jsx-runtime-B6kdoens.js";
import { createGlobalStyle as a } from "styled-components";
import s from "./components/CssBaseline/normalize.es.js";
import { d as l } from "./styles-DFJgZZAt.js";
import { getPaletteText as m, getPaletteBackground as n } from "./components/Theme/utils.es.js";
const i = a`
  ${s}
  ${l}
  body {
    font-family: "Inter", sans-serif;

    color: ${m("default")};
    background: ${n("primary.default")};

    margin: 0;
    padding: 0;
  }
  ${(o) => {
  var e, t;
  return (t = (e = o.theme.components) == null ? void 0 : e.CssBaseline) == null ? void 0 : t.styleOverrides;
}}
`, y = () => /* @__PURE__ */ r.jsx(i, {});
export {
  y as C
};
