import React from 'react';
import { Box } from '@mui/material';
import defaultSmallLogo from 'src/assets/img/monogram.svg';
import simplexityLargeLogo from 'src/assets/img/navigation-logo-simplexity.svg';
import connectLargeLogo from 'src/assets/img/navigation-logo-connect.svg';
import freedomLargeLogo from 'src/assets/img/navigation-logo-freedom.svg';
import { useSelector } from 'src/hooks';
import { NoChildrenProp as NoProps } from '../common';
import OncoreFlexMenuRow from './OncoreFlexMenuRow';

const OncoreFlexMenuLogo: React.FC<NoProps> = () => {
  const { branding, currentContext } = useSelector((s) => s.app);
  const {
    smallLogoUrl,
    largeLogoUrl
  } = branding || { };

  let defaultLargeLogoImg = simplexityLargeLogo;
  if (currentContext.context === 'user' || currentContext.context === 'agency' || currentContext.context === 'enterprise') {
    switch (currentContext.product) {
      case 'simplexity':
        defaultLargeLogoImg = simplexityLargeLogo;
        break;
      case 'connect':
        defaultLargeLogoImg = connectLargeLogo;
        break;
      case 'freedom':
        defaultLargeLogoImg = freedomLargeLogo;
        break;
      default:
    }
  }

  const smallLogo = (
    <img
      alt=""
      src={smallLogoUrl ?? defaultSmallLogo}
      style={{
        maxWidth: '42px',
        maxHeight: '42px'
      }}
    />
  );

  const largeLogo = (
    <Box paddingX={2} paddingY={5} marginLeft="auto">
      <img
        alt=""
        src={largeLogoUrl ?? defaultLargeLogoImg}
      />
    </Box>
  );

  return (
    <OncoreFlexMenuRow icon={smallLogo}>
      {largeLogo}
    </OncoreFlexMenuRow>
  );
};

export default OncoreFlexMenuLogo;
