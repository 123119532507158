import styled from 'styled-components';
import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

const MessageLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
`;

type MessageLayoutProps = {
  headerTitle: string;
  headerImage: ReactNode;
  message: string;
  footer?: ReactNode;
};

const MessageLayout = (props: MessageLayoutProps) => {
  const {
    headerTitle,
    headerImage,
    message,
    footer,
  } = props;

  return (
    <MessageLayoutContainer>
      <Box pb={1}>
        {headerImage}
      </Box>
      <Typography
        variant="h2">
        {headerTitle}
      </Typography>
      <Typography
        variant="subtitle1">
        {message}
      </Typography>
      {
        footer && (
          <Box pt={3} width="100%">
            {footer}
          </Box>
        )
      }
    </MessageLayoutContainer>
  );
};

export default MessageLayout;
