const x = {
  xxs: "320px",
  xs: "384px",
  sm: "480px",
  md: "560px",
  lg: "640px",
  xl: "768px",
  "2xl": "1024px",
  "3xl": "1280px",
  "4xl": "1440px",
  "5xl": "1600px",
  "6xl": "1920px",
  paragraphMax: "720px"
}, p = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: x
}, Symbol.toStringTag, { value: "Module" }));
export {
  x as a,
  p as w
};
