import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RouterRedirect, UserFeature, UserPermission } from '@oncore/shared';
import { UserContext } from 'src/store/appSlice';
import { links } from 'src/components/chrome';
import { childRouteFor, innerChildRouteFor, layoutFor } from './helpers';
import UserContextSwitch from './UserContextSwitch';
import AppLoader from 'src/spike/AppLoader';

const UserTimesheetEntry = React.lazy(() => import('src/pages/user/TimesheetEntry'));
const UserTimesheets = React.lazy(() => import('src/pages/user/Timesheets'));
const UserTimesheetRedirect = React.lazy(() => import('src/pages/user/Timesheet/Redirect'));
const UserTimesheetRoot = React.lazy(() => import('src/pages/user/Timesheet'));
const UserTimesheetDetails = React.lazy(() => import('src/pages/user/Timesheet/Details'));
const UserPayslips = React.lazy(() => import('src/pages/user/Payslips'));
const UserInvoices = React.lazy(() => import('src/pages/user/Invoices'));
const UserExpenses = React.lazy(() => import('src/pages/user/Expenses'));
const UserExpenseNew = React.lazy(() => import('src/pages/user/ExpenseNew'));
const UserExpense = React.lazy(() => import('src/pages/user/Expense'));

const NotFoundView = React.lazy(() => import('src/spike/Notice/NotFoundErrorNotice'));

export const getUserRoutes = (context: UserContext): RouteObject => {
  const features = context.features;
  const permissions = context.permissions;

  const featTimesheets = features.includes(UserFeature.Timesheets);
  const featPayslips = features.includes(UserFeature.Payslips);
  const featInvoices = features.includes(UserFeature.Invoices);
  const featExpenses = features.includes(UserFeature.Expenses);

  const permTimesheets = permissions.includes(UserPermission.Timesheets);
  const permPayslips = permissions.includes(UserPermission.Payslips);
  const permInvoices = permissions.includes(UserPermission.InvoicesUser);
  const permExpenses = permissions.includes(UserPermission.ExpensesUser);
  const permExpensesClientPaid = permissions.includes(UserPermission.ExpensesCreateClientPaid);
  const permExpensesPackaged = permissions.includes(UserPermission.ExpensesCreatePackaged);

  const children: RouteObject[] = [];

  let defaultPage: string | undefined = undefined;

  if (featTimesheets && permTimesheets) {
    defaultPage = links.user.entry;
    children.push(childRouteFor('entry', <UserTimesheetEntry />));
    children.push(childRouteFor('timesheets', <UserTimesheets />));

    children.push(childRouteFor('timesheets/:timesheetID', <UserTimesheetRedirect />, [
      layoutFor(<UserTimesheetRoot />, [
        innerChildRouteFor('details', <UserTimesheetDetails />)
      ])
    ]));
  }

  if (featPayslips && permPayslips) {
    defaultPage = defaultPage || links.user.payslips;
    children.push(childRouteFor('payslips', <UserPayslips />));
  }

  if (featInvoices && permInvoices) {
    defaultPage = defaultPage || links.user.invoices;
    children.push(childRouteFor('invoices', <UserInvoices />));
  }

  if (featExpenses && permExpenses) {
    defaultPage = defaultPage || links.user.expenses;
    children.push(childRouteFor('expenses', <UserExpenses />));
    children.push(childRouteFor('expenses/:expenseID', <UserExpense />));

    if (permExpensesClientPaid || permExpensesPackaged) {
      children.push(childRouteFor('expenses/new', <UserExpenseNew />));
    }
  }

  children.push({
    path: '*',
    element: (
      <React.Suspense fallback={<AppLoader />}>
        <NotFoundView />
      </React.Suspense>
    )
  });

  if (defaultPage) {
    children.push({
      index: true,
      element: (<RouterRedirect to={defaultPage} />)
    });
  }

  return ({
    path: 'my',
    children: [{
      element: (<UserContextSwitch />),
      children
    }]
  });
};
