import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'src/hooks';
import { links } from '../chrome';
import { OncoreFlexContextQuery, OncoreFlexContextQuery$data as ContextsResponse } from '../../__generated__/OncoreFlexContextQuery.graphql';
import { fetchQuery } from 'relay-runtime';
import environment from '../../environment';
import Query from '../../hooks/OncoreFlexContextQuery';
import { useEffect } from 'react';
import { setContexts, setErrorContext } from '../../store/appSlice';
import { useSetAnalyticsUserProperties } from '@oncore/shared';
import OncoreLoader from '@oncore/ui/shared/OncoreLoader';

const getContexts = (): Promise<ContextsResponse | undefined> => {
  return fetchQuery<OncoreFlexContextQuery>(
    environment,
    Query,
    {}
  ).toPromise();
};

export type Props = {
  children?: never;
};

export const AuthGuard = (_: Props) => {

  const location = useLocation();

  const dispatch = useDispatch();
  const { token, isContextStale, currentContext } = useSelector((s) => s.app);
  const setAnalyticsUserProperties = useSetAnalyticsUserProperties();

  useEffect(() => {
    if (token && isContextStale) {
      getContexts()
        .then((response) => response?.user && dispatch(setContexts(response)))
        .catch((error) => dispatch(setErrorContext(error)))
        .finally(() => {
          if (currentContext.context === 'agency' || currentContext.context === 'enterprise') {
            setAnalyticsUserProperties({
              tenant: currentContext.displayName,
              context: currentContext.context,
              features: currentContext.features,
              permissions: currentContext.permissions
            });
          } else if (currentContext.context === 'user') {
            setAnalyticsUserProperties({
              tenant: 'none',
              context: currentContext.context,
              features: currentContext.features,
              permissions: currentContext.permissions
            });
          }
        });
    }
  }, [token, isContextStale]);

  if (!token) {
    return (
      <Navigate to={links.home} state={location} />
    );
  }

  if (currentContext.context === 'loading') {
    return (
      <OncoreLoader 
        variant="splash"
        loadingText="Fetching user data..."/>
    );
  }

  return (
    <Outlet />
  );
};

export default AuthGuard;
