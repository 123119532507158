import { PropsWithChildren, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { setTokenRoutine } from 'src/state/user/reducer';
import { getCurrentUserSelector } from 'src/state/user/selectors';
import { setToken } from 'src/store/appSlice';
import OncoreLoader from '@oncore/ui/shared/OncoreLoader';

type AuthGuardProps = PropsWithChildren;

export const AuthGuard = (props: AuthGuardProps) => {
  const { children } = props;
  const { token } = useSelector(getCurrentUserSelector());
  const dispatch = useDispatch();
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    user,
  } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect().then();
    }
  }, [ isAuthenticated, isLoading ]);

  useEffect(() => {
    (async () => {
      if (isAuthenticated && !token && !isLoading) {
        const bt = await getAccessTokenSilently();
        dispatch(setToken(bt));
        dispatch(setTokenRoutine.trigger(bt));
      }
    })();
  }, [ token, isLoading, isAuthenticated ]);

  if (!user && isAuthenticated) {
    throw new Error('[AuthGuard] User is authenticated, however user information is not available');
  }

  if (isLoading) {
    return (
      <OncoreLoader 
        variant="splash"
        loadingText="Checking credentials..."/>
    );
  }

  if (!isAuthenticated || !token) {
    return null;
  }

  return children;
};

export default AuthGuard;
