import { j as t } from "./jsx-runtime-B6kdoens.js";
import x from "styled-components";
import { r as n } from "./polished.esm-B6VUs5va.js";
import { i as g } from "./isExhaustive-RFXKPwPh.js";
import { g as y } from "./getInitials-DBGApqk2.js";
import { I as f } from "./Icon-C3nZvRjn.js";
import "react";
import "./components/Icon/countryVariants.es.js";
import "./styles-DFJgZZAt.js";
import { T as h } from "./Typography-GJu-MHyZ.js";
import { avatarSizeStyles as u } from "./components/Avatar/styles.es.js";
import { getRadii as z, getPaletteColors as a, getPaletteForeground as b } from "./components/Theme/utils.es.js";
const c = x.div`
  border-radius: ${z("full")};

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  
  border-color: ${(r) => n(a("gray.900")(r), 0.08)};
  background-color: ${a("gray.200")};
  color: ${b("quaternary.default")};
  
  ${(r) => u[r.$size]};

  &:focus {
    outline: 4px solid ${(r) => n(a("gray.400")(r), 0.14)};
  }
`, _ = (r) => {
  const {
    size: s = "sm",
    className: l,
    ...o
  } = r;
  switch (o.variant) {
    case "icon": {
      const {
        variant: m,
        icon: i,
        ...e
      } = o;
      return /* @__PURE__ */ t.jsx(
        c,
        {
          $size: s,
          className: l,
          ...e,
          children: /* @__PURE__ */ t.jsx(f, { ...i })
        }
      );
    }
    case "initials": {
      const {
        variant: m,
        displayName: i,
        ...e
      } = o, d = r.size === "xxl" ? "display" : "text", p = r.size === "xxl" ? "xs" : r.size;
      return /* @__PURE__ */ t.jsx(
        c,
        {
          $size: s,
          className: l,
          ...e,
          children: /* @__PURE__ */ t.jsx(
            h,
            {
              weight: "semibold",
              color: "placeholder",
              variant: d,
              size: p,
              children: y(i)
            }
          )
        }
      );
    }
    default:
      g(o);
  }
};
export {
  _ as A
};
