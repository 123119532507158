const e = {
  xs: "0px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1536px"
}, t = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: e
}, Symbol.toStringTag, { value: "Module" }));
export {
  e as a,
  t as b
};
