import { css as h } from "styled-components";
const t = {
  sm: h`
    width: 34px;
    height: 34px;
  `,
  md: h`
    width: 38px;
    height: 38px;
  `,
  lg: h`
    width: 42px;
    height: 42px;
  `,
  xl: h`
    width: 46px;
    height: 46px;
  `,
  xxl: h`
    width: 50px;
    height: 50px;
  `
}, i = t, p = {
  sm: h`
    width: 24px;
    height: 24px;
  `,
  md: h`
    width: 28px;
    height: 28px;
  `,
  lg: h`
    width: 32px;
    height: 32px;
  `,
  xl: h`
    width: 36px;
    height: 36px;
  `,
  xxl: h`
    width: 40px;
    height: 40px;
  `
}, e = {
  sm: h`
    width: 16px;
    height: 16px;
  `,
  md: h`
    width: 20px;
    height: 20px;
  `,
  lg: h`
    width: 24px;
    height: 24px;
  `,
  xl: h`
    width: 28px;
    height: 28px;
  `,
  xxl: h`
    width: 32px;
    height: 32px;
  `
};
export {
  e as iconSizeStyles,
  p as innerSizeStyles,
  i as outerSizeStyles,
  t as sizeStyles
};
