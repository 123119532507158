import { useFloating as s, autoUpdate as f, flip as i, shift as l, offset as p } from "@floating-ui/react-dom";
const r = 10, d = ({
  flipOptions: t,
  shiftOptions: o,
  floatingOptions: e,
  offset: a = r,
  customMiddlewares: n = []
}) => ({
  ...s({
    whileElementsMounted: f,
    middleware: [
      i(t),
      l(o),
      p(a),
      ...n
    ],
    ...e
  })
});
export {
  d as u
};
