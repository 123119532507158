import { useState as n } from "react";
const c = (s) => {
  const [
    o,
    e
  ] = n(s || !1);
  return {
    isOpen: o,
    onOpen: () => e(!0),
    onClose: () => e(!1)
  };
};
export {
  c as u
};
