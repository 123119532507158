import { graphql } from 'react-relay';

export const notificationsInitialQuery = graphql`
    query NotificationsInitialQuery(
        $status: NotificationStatus
    ) {
        user {
            notifications (filter: {
                skip: 0
                take: 10
                orderBy: createdAt
                orderDir: descending
                status: $status
            }) {
                continuationToken
                totalCount
                items {
                    id
                    subject
                    status
                    text
                    acknowledgedAt
                    createdAt
                    actionUrl
                    kind
                }
            }
        }
    }
`;

export const notificationsFurtherQuery = graphql`
    query NotificationsFurtherQuery ($continuationToken: String!) {
        user {
            notifications (continuationToken: $continuationToken) {
                continuationToken
                items {
                    id
                    subject
                    status
                    text
                    acknowledgedAt
                    createdAt
                    actionUrl
                    kind
                }
            }
        }
    }
`;

export const notificationsSubscription = graphql`
    subscription NotificationsSubscription {
        notification: notifications {
            id
            subject
            status
            text
            acknowledgedAt
            createdAt
            actionUrl
            kind
        }
    }`;

export const markNotificationAsReadMutation = graphql`
    mutation NotificationsMarkAsReadMutation ($notificationID: ID!) {
        notification: markNotificationAsRead(notificationID: $notificationID) {
            id
            subject
            status
            text
            acknowledgedAt
            createdAt
            actionUrl
            kind
        }
    }
`;

export const markNotificationAsUnreadMutation = graphql`
    mutation NotificationsMarkAsUnreadMutation ($notificationID: ID!) {
        notification: markNotificationAsUnread(notificationID: $notificationID) {
            id
            subject
            status
            text
            acknowledgedAt
            createdAt
            actionUrl
            kind
        }
    }
`;

export const markAllNotificationsAsReadMutation = graphql`
    mutation NotificationsMarkAllAsReadMutation {
        notification: markAllNotificationsAsRead {
            id
            subject
            status
            text
            acknowledgedAt
            createdAt
            actionUrl
            kind
        }
    }
`;

