import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Group, Person } from '@mui/icons-material';
import { Box, ButtonBase, Menu, MenuItem, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import styledComponent from 'styled-components';
import {
  useEnvironment,
  useSnackbar,
  StyledAvatar,
  TrackedMenuItem
} from '@oncore/shared';
import { useDispatch, useSelector } from 'src/hooks';
import { setMenuOpen, Context } from 'src/store/appSlice';
import { NoChildrenProp as NoProps } from '../common';
import links from './links';

const AccountBox = styled(Box)(({ theme }) => ({
  ...theme.typography.h5,
  margin: theme.spacing(0, 2),
  padding: theme.spacing(0, 2),
  alignSelf: 'stretch',
  height: '64px',
}));

const AccountName = styledComponent.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 16px;
  max-width: 200px;
  text-align: left;
  line-height: 16px;
  @media (max-width: 460px) {
    max-width: calc(100vw - 270px);
  }
  small {
    display: block;
    font-weight: 400;
    opacity: 0.5;
    margin-top: 2px;
  }
`;

const ContextName = styledComponent.div`
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OncoreFlexHeaderAccount: React.FC<NoProps> = () => {
  const navigate = useNavigate();

  useAuth0();

  const { enqueueSnackbar } = useSnackbar();
  const { displayName, currentContext, contexts } = useSelector((s) => s.app);
  const dispatch = useDispatch();
  const {
    version
  } = useEnvironment();

  const [isOpen, setOpen] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  const openMenu = (): void => {
    setOpen(true);
  };

  const closeMenu = (): void => {
    setOpen(false);
  };

  const doContextSwitch = async (context: Context): Promise<void> => {
    try {
      dispatch(setMenuOpen(false));
      switch (context.context) {
        case 'user':
          navigate(links.user.root);
          break;
        case 'enterprise':
          navigate(links.enterprise.root(context.id));
          break;
        case 'agency':
          navigate(links.agency.root(context.id));
          break;
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Unable to switch to ${context.context === 'user' ? 'user context' : context.displayName}`, {
        variant: 'error'
      });
    }
  };

  const doProfile = (): void => {
    closeMenu();
    navigate(links.user.profile.view);
  };

  const doLogout = async (): Promise<void> => {
    try {
      closeMenu();
      navigate(links.logout);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  const menu = (
    <Menu
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      keepMounted
      anchorEl={ref.current}
      open={isOpen}
    >
      <MenuItem disabled>
        {version}
      </MenuItem>
      <Divider />
      {contexts.map((context) => (
        <TrackedMenuItem
          trackedName="Context item"
          key={context.context === 'user' ? 'user' : context.id}
          onClick={() => {
            closeMenu();
            doContextSwitch(context);
          }}
        >
          <ContextName>
            {context.context === 'user' ? displayName : context.displayName}
          </ContextName>
        </TrackedMenuItem>
      ))}
      <Divider />
      <TrackedMenuItem onClick={doProfile} trackedName="Profile">
        Profile
      </TrackedMenuItem>
      <Divider />
      <TrackedMenuItem onClick={doLogout} trackedName="Logout">
        Logout
      </TrackedMenuItem>
    </Menu>
  );

  if (currentContext.context === 'loading' || currentContext.context === 'empty') {
    return <></>;
  }

  const accountName = currentContext.context === 'user'
    ? displayName
    : (currentContext.context === 'enterprise' || currentContext.context === 'agency')
      ? currentContext.displayName
      : '';

  return (
    <>
      <AccountBox
        data-dd-action-name="Header account menu"
        component={ButtonBase}
        onClick={openMenu}
        mx={2}
        // @ts-ignore
        ref={ref}
      >
        <StyledAvatar>
          {currentContext.context === 'user' && (<Person />)}
          {(currentContext.context === 'agency' || currentContext.context === 'enterprise') && (<Group />)}
        </StyledAvatar>
        <AccountName>
          {accountName}
          {(currentContext.context === 'agency' || currentContext.context === 'enterprise') && (
            <small>
              {displayName}
            </small>
          )}
        </AccountName>
      </AccountBox>
      {menu}
    </>
  );
};

export default OncoreFlexHeaderAccount;
