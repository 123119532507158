import React from 'react';
import { useSelector } from 'src/hooks';
import { NoChildrenProp as NoProps } from '../common';
import Notifications from '../NotificationsView';
import { UserFeature } from '@oncore/shared';
import { UserContext } from 'src/store/appSlice';


const OncoreFlexHeaderNotifications: React.FC<NoProps> = () => {
  const { contexts } = useSelector((s) => s.app);
  const userContext = contexts.find((x) => x.context === 'user');
  const enabled = userContext ? (userContext as UserContext).features.includes(UserFeature.WebNotifications) : false;

  return enabled ? <Notifications /> : <></>;
};

export default OncoreFlexHeaderNotifications;
