import { 
  useState,
  PropsWithChildren,
  useEffect,
} from 'react';

import { LogoProps } from '@oncore/ui/Logo';
import AppTitleContext from './AppTitleContext';

const PRODUCT_TEXT = {
  oncoreflex: 'Oncoreflex',
  freedom: 'Freedom',
  simplexity: 'Simplexity',
  connect: 'Conntect',
  omp: 'OMP',
} as const;

export type AppTitleProps = PropsWithChildren<{
  name: string;
  product?: LogoProps['variant'];
}>;

const AppTitle = (props: AppTitleProps) => {
  const {
    name,
    product,
    children,
  } = props;

  const [
    title,
    setTitle,
  ] = useState('');

  const productText = product ? PRODUCT_TEXT[product] : undefined;

  const chromeTitle = [
    title,
    productText,
    name,
  ].filter(Boolean).join(' - ');

  useEffect(() => {
    window.document.title = chromeTitle;
  }, [chromeTitle]);

  return (
    <AppTitleContext.Provider
      value={{
        title: chromeTitle,
        setTitle,
      }}>
      {children}
    </AppTitleContext.Provider>
  );
};

export default AppTitle;
