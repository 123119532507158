import { FILE_ICON_MIME_EXTENSION_MAPPER as I, FILE_ICON_EXTENSION_MIME_MAPPER as E } from "./constants.es.js";
const r = (e) => I[e], s = (e) => {
  const t = Object.keys(E).find((n) => n === e);
  if (t)
    return E[t];
};
export {
  r as getMIMEExtension,
  s as getMIMEType
};
