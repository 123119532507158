import {
  SubMenuItem,
  TopMenuItem
} from 'src/components/chrome';
import { useMenuItemVisibility } from 'src/components/chrome/hooks';
import { SideBarItem } from '@oncore/ui/SideBar';

type ChromeSideBarItemProps = {
  menuItem: TopMenuItem | SubMenuItem;
};

const ChromeSideBarItem = (props: ChromeSideBarItemProps) => {
  const { menuItem } = props;
  const isMenuItemVisible = useMenuItemVisibility(props.menuItem);

  if (!isMenuItemVisible) {
    return null;
  }

  const title = typeof menuItem.title === 'function' ? menuItem.title() : menuItem.title;
  const {
    to = '',
    icon,
  } = menuItem.spike;

  return (
    <SideBarItem
      key={menuItem.id}
      text={title}
      to={to}
      icon={icon ? {
        variant: icon,
      } : undefined}
    />
  );
};

export default ChromeSideBarItem;
