import React from 'react';
import { RouteObject } from 'react-router-dom';
import {
  EnterpriseFeature,
  EnterprisePermission,
  RouterRedirect,
} from '@oncore/shared';
import { EnterpriseContext } from 'src/store/appSlice';
import { links } from 'src/components/chrome';
import { childRouteFor, innerChildRouteFor, layoutFor } from './helpers';
import TenantContextSwitch from './TenantContextSwitch';
import AppLoader from 'src/spike/AppLoader';

import enterpriseLinks from 'src/spike/pages/links/enterpriseLinks';

const EnterprisePositions = React.lazy(() => import('src/spike/pages/EnterprisePositions'));
const EnterprisePositionsNew = React.lazy(() => import('src/spike/pages/EnterpriseCreatePosition/PositionCreatePage'));
const EnterprisePositionIndex = React.lazy(() => import('src/spike/pages/EnterprisePosition/EnterprisePositionIndexPage'));
const EnterprisePosition = React.lazy(() => import('src/spike/pages/EnterprisePosition/PositionPage'));
const EnterprisePositionDetails = React.lazy(() => import('src/spike/pages/EnterprisePositionDetails/PositionDetailsPage'));
const EnterprisePositionRate = React.lazy(() => import('src/spike/pages/EnterprisePositionRate/PositionRatePage'));
const EnterprisePositionStakeholders = React.lazy(() => import('src/spike/pages/EnterprisePositionStakeholders/PositionStakeholdersPage'));
const EnterprisePositionFees = React.lazy(() => import('src/spike/pages/EnterprisePositionFees/PositionFeesPage'));
const EnterprisePlacementsNew = React.lazy(() => import('src/pages/enterprise/PlacementNew'));
const EnterpriseContracts = React.lazy(() => import('src/pages/enterprise/Contracts'));
const EnterpriseContractRoot = React.lazy(() => import('src/pages/enterprise/Contract/Contract'));
const EnterpriseContractRedirect = React.lazy(() => import('src/pages/enterprise/Contract/Redirect'));
const EnterpriseContractDetails = React.lazy(() => import('src/pages/enterprise/Contract/Details'));
const EnterpriseContractApprovers = React.lazy(() => import('src/pages/enterprise/Contract/Approvers'));
const EnterpriseTimesheets = React.lazy(() => import('src/pages/enterprise/Timesheets'));
const EnterpriseTimesheetRoot = React.lazy(() => import('src/pages/enterprise/Timesheet/Timesheet'));
const EnterpriseTimesheetRedirect = React.lazy(() => import('src/pages/enterprise/Timesheet/Redirect'));
const EnterpriseTimesheetDetails = React.lazy(() => import('src/pages/enterprise/Timesheet/Details'));
const EnterpriseExpenses = React.lazy(() => import('src/pages/enterprise/Expenses'));
const EnterpriseExpense = React.lazy(() => import('src/pages/enterprise/Expense'));
const EnterpriseAdminUsers = React.lazy(() => import('src/pages/enterprise/admin/Users'));
const EnterpriseAdminUserInvite = React.lazy(() => import('src/pages/enterprise/admin/UserNew'));
const EnterpriseAdminUserRoot = React.lazy(() => import('src/pages/enterprise/admin/User/User'));
const EnterpriseAdminUserRedirect = React.lazy(() => import('src/pages/enterprise/admin/User/Redirect'));
const EnterpriseAdminUserDetails = React.lazy(() => import('src/pages/enterprise/admin/User/Details'));
const EnterpriseAdminGroups = React.lazy(() => import('src/pages/enterprise/admin/Groups'));
const EnterpriseAdminGroupNew = React.lazy(() => import('src/pages/enterprise/admin/GroupNew'));
const EnterpriseAdminGroupRoot = React.lazy(() => import('src/pages/enterprise/admin/Group/Group'));
const EnterpriseAdminGroupRedirect = React.lazy(() => import('src/pages/enterprise/admin/Group/Redirect'));
const EnterpriseAdminGroupDetails = React.lazy(() => import('src/pages/enterprise/admin/Group/Details'));
const EnterpriseAdminOrgUnits = React.lazy(() => import('src/pages/enterprise/admin/OrgUnits'));
const EnterpriseAdminOrgUnitsRedirect = React.lazy(() => import('src/pages/enterprise/admin/OrgUnits/Redirect'));
const EnterpriseAdminOrgUnitsStructure = React.lazy(() => import('src/pages/enterprise/admin/OrgUnits/Structure'));
const EnterpriseAdminOrgUnitsSettings = React.lazy(() => import('src/pages/enterprise/admin/OrgUnits/Settings'));
const EnterpriseAdminOrgUnitNew = React.lazy(() => import('src/pages/enterprise/admin/OrgUnits/OrgUnitNew'));
const EnterpriseAdminOrgUnit = React.lazy(() => import('src/pages/enterprise/admin/OrgUnits/OrgUnit'));
const EnterpriseAdminLocations = React.lazy(() => import('src/pages/enterprise/admin/Locations'));
const EnterpriseAdminLocationsRedirect = React.lazy(() => import('src/pages/enterprise/admin/Locations/Redirect'));
const EnterpriseAdminLocationsStructure = React.lazy(() => import('src/pages/enterprise/admin/Locations/Structure'));
const EnterpriseAdminLocationsSettings = React.lazy(() => import('src/pages/enterprise/admin/Locations/Settings'));
const EnterpriseAdminLocationNew = React.lazy(() => import('src/pages/enterprise/admin/Locations/LocationNew'));
const EnterpriseAdminLocation = React.lazy(() => import('src/pages/enterprise/admin/Locations/Location'));
const EnterpriseAdminScreenings = React.lazy(() => import('src/pages/enterprise/admin/Screenings'));
const EnterpriseAdminRateCards = React.lazy(() => import('src/spike/pages/EnterpriseAdminRateCards'));
const EnterpriseAdminRateCardNew = React.lazy(() => import('src/spike/pages/enterprise/admin/RateCardNew'));
const EnterpriseAdminRateCardRoot = React.lazy(() => import('src/spike/pages/enterprise/admin/RateCard/Root'));
const EnterpriseAdminRateCardRedirect = React.lazy(() => import('src/spike/pages/enterprise/admin/RateCard/Redirect'));
const EnterpriseAdminRateCardDetails = React.lazy(() => import('src/spike/pages/enterprise/admin/RateCard/Details/Details'));
const EnterpriseAdminPositions = React.lazy(() => import('src/spike/pages/enterprise/admin/Positions/EnterpriseAdminPositionsPage'));
const EnterpriseAdminPositionsRedirect = React.lazy(() => import('src/spike/pages/enterprise/admin/Positions/Redirect'));
const EnterpriseAdminPositionsDefaultReviewers = 
  React.lazy(() => import('src/spike/pages/enterprise/admin/Positions/DefaultReviewers/EnterpriseAdminPositionsDefaultReviewers'));
const EnterpriseHome = React.lazy(() => import('src/pages/enterprise/Home'));
const NotFoundView = React.lazy(() => import('src/spike/Notice/NotFoundErrorNotice'));

export const getEnterpriseRoutes = (context: EnterpriseContext): RouteObject => {
  const features = context.features;
  const permissions = [
    ...context.permissions,
    ...context.groups.reduce((x, a) => [...x, ...a.permissions], [] as EnterprisePermission[])
  ].filter((value, index, self) => self.indexOf(value) === index);

  const featPositions = features.includes(EnterpriseFeature.Positions);
  const featPlacements = features.includes(EnterpriseFeature.Placements);
  const featContracts = features.includes(EnterpriseFeature.Contracts);
  const featTimesheets = features.includes(EnterpriseFeature.Timesheets);
  const featExpenses = features.includes(EnterpriseFeature.Expenses);
  const featOrganisationalUnits = features.includes(EnterpriseFeature.OrganisationalUnits);
  const featRateCards = features.includes(EnterpriseFeature.RateCards);
  const featHome = features.includes(EnterpriseFeature.SimplexityHomepage);

  const permTenantAdmin = permissions.includes(EnterprisePermission.TenantAdmin);

  // TenantAdmin permission is like a Super-Admin permission in Enterprise,
  // If it is granted, we shall consider all other permissions as granted as well.
  const permPositionUser = permTenantAdmin || permissions.includes(EnterprisePermission.PositionUser);
  const permPositionCreate = permTenantAdmin || permissions.includes(EnterprisePermission.PositionCreate);
  const permPlacementUser = permTenantAdmin || permissions.includes(EnterprisePermission.PlacementUser);
  const permPlacementCreate = permTenantAdmin || permissions.includes(EnterprisePermission.PlacementCreate);
  const permContractUser = permTenantAdmin || permissions.includes(EnterprisePermission.ContractUser);
  const permTimesheetUser = permTenantAdmin || permissions.includes(EnterprisePermission.TimesheetUser);
  const permExpenseUser = permTenantAdmin || permissions.includes(EnterprisePermission.ExpenseUser);

  const children: RouteObject[] = [];

  let defaultPage: string | undefined = undefined;

  if (featPositions && permPositionUser) {
    defaultPage = links.enterprise.positions(context.id);
    children.push(childRouteFor(enterpriseLinks.positions({
      params: {
        tenantID: ':tenantID',
      },
    }), <EnterprisePositions />));
    children.push(childRouteFor(enterpriseLinks.position({
      params: {
        tenantID: ':tenantID',
        positionID: ':positionID',
      },
    }), <EnterprisePositionIndex />, [
      layoutFor(<EnterprisePosition />, [
        innerChildRouteFor(enterpriseLinks.positionDetails({
          params: {
            tenantID: ':tenantID',
            positionID: ':positionID',
          },
        }), <EnterprisePositionDetails />),
        innerChildRouteFor(enterpriseLinks.positionRate({
          params: {
            tenantID: ':tenantID',
            positionID: ':positionID',
          },
        }), <EnterprisePositionRate />),
        innerChildRouteFor(enterpriseLinks.positionStakeholders({
          params: {
            tenantID: ':tenantID',
            positionID: ':positionID',
          },
        }), <EnterprisePositionStakeholders />),
        innerChildRouteFor(enterpriseLinks.positionFees({
          params: {
            tenantID: ':tenantID',
            positionID: ':positionID',
          },
        }), <EnterprisePositionFees />),
      ])
    ]));

    if (permPositionCreate) {
      children.push(childRouteFor(enterpriseLinks.positionNew({
        params: {
          tenantID: ':tenantID',
        },
      }), <EnterprisePositionsNew />, undefined, true));
    }
  }

  if (featPlacements && permPlacementUser && permPlacementCreate) {
    defaultPage = defaultPage || links.enterprise.placement.new(context.id);
    children.push(childRouteFor('placements/new', <EnterprisePlacementsNew />));
  }

  if (featContracts && permContractUser) {
    defaultPage = defaultPage || links.enterprise.contracts(context.id);
    children.push(childRouteFor('contracts', <EnterpriseContracts />));
    children.push(childRouteFor('contracts/:contractID', <EnterpriseContractRedirect />, [
      layoutFor(<EnterpriseContractRoot />, [
        innerChildRouteFor('details', <EnterpriseContractDetails />),
        innerChildRouteFor('approvers', <EnterpriseContractApprovers />)
      ])
    ]));
  }

  if (featTimesheets && permTimesheetUser) {
    defaultPage = defaultPage || links.enterprise.timesheets(context.id);
    children.push(childRouteFor('timesheets', <EnterpriseTimesheets />));
    children.push(childRouteFor('timesheets/:timesheetID', <EnterpriseTimesheetRedirect />, [
      layoutFor(<EnterpriseTimesheetRoot />, [
        innerChildRouteFor('details', <EnterpriseTimesheetDetails />),
      ])
    ]));
  }

  if (featExpenses && permExpenseUser) {
    defaultPage = defaultPage || links.enterprise.expenses(context.id);
    children.push(childRouteFor('expenses', <EnterpriseExpenses />));
    children.push(childRouteFor('expenses/:expenseID', <EnterpriseExpense />));
  }

  if (featHome) {
    defaultPage = links.enterprise.root(context.id);
    children.push(childRouteFor('',   <EnterpriseHome />));
  }

  if (permTenantAdmin) {
    defaultPage = defaultPage || links.enterprise.admin.users(context.id);
    children.push(childRouteFor('admin/users', <EnterpriseAdminUsers />));
    children.push(childRouteFor('admin/users/invite', <EnterpriseAdminUserInvite />));
    children.push(childRouteFor('admin/users/:userID', <EnterpriseAdminUserRedirect />, [
      layoutFor(<EnterpriseAdminUserRoot />, [
        innerChildRouteFor('details', <EnterpriseAdminUserDetails />)
      ])
    ]));
    children.push(childRouteFor('admin/groups', <EnterpriseAdminGroups />));
    children.push(childRouteFor('admin/groups/new', <EnterpriseAdminGroupNew />));
    children.push(childRouteFor('admin/groups/:groupID', <EnterpriseAdminGroupRedirect />, [
      layoutFor(<EnterpriseAdminGroupRoot />, [
        innerChildRouteFor('details', <EnterpriseAdminGroupDetails />)
      ])
    ]));

    if (featOrganisationalUnits) {
      children.push(childRouteFor('admin/organisation', <EnterpriseAdminOrgUnitsRedirect />, [
        layoutFor(<EnterpriseAdminOrgUnits />, [
          innerChildRouteFor('structure', <EnterpriseAdminOrgUnitsStructure />),
          innerChildRouteFor('settings', <EnterpriseAdminOrgUnitsSettings />)
        ])
      ]));
      children.push(childRouteFor('admin/organisation/new', <EnterpriseAdminOrgUnitNew />));
      children.push(childRouteFor('admin/organisation/structure/:unitID', <EnterpriseAdminOrgUnit />));
    }

    children.push(childRouteFor('admin/locations', <EnterpriseAdminLocationsRedirect />, [
      layoutFor(<EnterpriseAdminLocations />, [
        innerChildRouteFor('structure', <EnterpriseAdminLocationsStructure />),
        innerChildRouteFor('settings', <EnterpriseAdminLocationsSettings />)
      ])
    ]));
    children.push(childRouteFor('admin/locations/new', <EnterpriseAdminLocationNew />));
    children.push(childRouteFor('admin/locations/structure/:locationID', <EnterpriseAdminLocation />));
    children.push(childRouteFor('admin/screenings', <EnterpriseAdminScreenings />));

    if (featRateCards) {
      children.push(childRouteFor(enterpriseLinks.adminRateCards({
        params: {
          tenantID: ':tenantID',
        },
      }), <EnterpriseAdminRateCards />));
      children.push(childRouteFor(enterpriseLinks.adminRateCardNew({
        params: {
          tenantID: ':tenantID',
        },
      }), <EnterpriseAdminRateCardNew />, undefined, true));
      children.push(childRouteFor(enterpriseLinks.adminRateCard({
        params: {
          tenantID: ':tenantID',
          rateCardID: ':rateCardID',
        },
      }), <EnterpriseAdminRateCardRedirect />, [
        layoutFor(<EnterpriseAdminRateCardRoot />, [
          innerChildRouteFor(enterpriseLinks.adminRateCardDetails({
            params: {
              tenantID: ':tenantID',
              rateCardID: ':rateCardID',
            },
          }), <EnterpriseAdminRateCardDetails />)
        ])
      ]));
    }

    if (featPositions) {
      const adminPositionsRoute = childRouteFor(
        enterpriseLinks.adminPositions({
          params: {
            tenantID: ':tenantID',
          },
        }),
        <EnterpriseAdminPositionsRedirect/>,
        [
          layoutFor(
            <EnterpriseAdminPositions/>,
            [
              innerChildRouteFor(
                enterpriseLinks.adminPositionsDefaultReviewers({
                  params: {
                    tenantID: ':tenantID',
                  },
                }),
                <EnterpriseAdminPositionsDefaultReviewers/>,
              ),
            ],
          )
        ]
      );

      children.push(adminPositionsRoute);
    }
  }

  children.push({
    path: '*',
    element: (
      <React.Suspense fallback={<AppLoader />}>
        <NotFoundView />
      </React.Suspense>
    )
  });

  if (defaultPage) {
    children.push({
      index: true,
      element: (<RouterRedirect to={defaultPage} />)
    });
  }

  return ({
    path: ':tenantID',
    children: [{
      element: (<TenantContextSwitch />),
      children
    }]
  });
};
