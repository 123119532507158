import { j as $ } from "./jsx-runtime-B6kdoens.js";
import d from "styled-components";
import { c as g } from "./clsx.m-BMVuQnLY.js";
import { t as s } from "./styles-DFJgZZAt.js";
const p = d.span`
  &.variant-display {
    &.size-xxl {
      ${s.variants.display.xxl}
    }
    &.size-xl {
      ${s.variants.display.xl}
    }
    &.size-lg {
      ${s.variants.display.lg}
    }
    &.size-md {
      ${s.variants.display.md}
    }
    &.size-sm {
      ${s.variants.display.sm}
    }
    &.size-xs {
      ${s.variants.display.xs}
    }
  }

  &.variant-text {
    &.size-xl {
      ${s.variants.text.xl}
    }
    &.size-lg {
      ${s.variants.text.lg}
    }
    &.size-md {
      ${s.variants.text.md}
    }
    &.size-sm {
      ${s.variants.text.sm}
    }
    &.size-xs {
      ${s.variants.text.xs}
    }
  }

  &.weight-regular {
    ${s.weights.regular}
  }
  &.weight-medium {
    ${s.weights.medium}
  }
  &.weight-semibold {
    ${s.weights.semibold}
  }
  &.weight-bold {
    ${s.weights.bold}
  }
  
  ${(i) => i.$color !== "none" ? s.colors[i.$color] : void 0};
`, z = (i) => {
  const {
    variant: t = "text",
    size: a = "md",
    weight: e = "regular",
    color: r = "primary",
    as: l = "span",
    className: o,
    children: m,
    ...n
  } = i, x = g(
    `variant-${t}`,
    `size-${a}`,
    `weight-${e}`,
    o
  );
  return /* @__PURE__ */ $.jsx(
    p,
    {
      ...n,
      className: x,
      as: l,
      $color: r,
      children: m
    }
  );
};
export {
  z as T
};
