import React from 'react';
import {
  useMediaQuery,
  useTheme,
} from '@mui/material';
import OncoreFlexMenuMobile from './OncoreFlexMenuMobile';
import OncoreFlexMenuDesktop, {
  Props as OncoreFlexMenuDesktopProps,
} from './OncoreFlexMenuDesktop';
import { useMenuList } from './hooks';

export type Props = Pick<OncoreFlexMenuDesktopProps, 'offsetTop'> & {
  children?: never;
};

const OncoreFlexMenu: React.FC<Props> = (props: Props) => {
  const { offsetTop } = props;
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const menuList = useMenuList();

  return (
    <>
      {
        isMobileView ? (
          <OncoreFlexMenuMobile
            items={menuList}
            offsetTop={offsetTop} />
        ) : (
          <OncoreFlexMenuDesktop
            items={menuList}
            offsetTop={offsetTop} />
        )
      }
    </>
  );
};

export default OncoreFlexMenu;
