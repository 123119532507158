/* eslint-disable react-hooks/rules-of-hooks */
import { Access } from 'src/types/account';
import { EnterprisePermissions, AgencyPermissions, UserPermissions, Permissions } from 'src/components/chrome';
import { useSelector } from './useStore';
import { useAgencyContext, useEnterpriseContext, useUserContext } from './useContext';
import { EnterprisePermission } from '@oncore/shared';

const DENIED: Access = {
  isAdmin: false,
  isGranted: false,
  identities: []
};

export function useEnterpriseAccess(permissions: EnterprisePermissions): Access {
  const { userID, displayName } = useSelector((s) => s.app);
  const context = useEnterpriseContext();

  const identities = [{
    id: userID,
    displayName: displayName,
    permissions: context.permissions
  }, ...context.groups];
  const admins = identities.filter((identity) => identity.permissions.includes(EnterprisePermission.TenantAdmin));
  if (admins.length) {
    return <Access>({
      isAdmin: true,
      isGranted: true,
      identities: admins
    });
  }
  const granted = identities.filter(
    (identity) => permissions.every((permission) => identity.permissions.includes(permission))
  );
  if (granted.length) {
    return <Access>({
      isAdmin: false,
      isGranted: true,
      identities: granted
    });
  }

  return DENIED;
}

export function useAgencyAccess(permissions: AgencyPermissions): Access {
  const { userID, displayName } = useSelector((s) => s.app);
  const context = useAgencyContext();

  const identities = [{
    id: userID,
    displayName: displayName,
    permissions: context.permissions
  }, ...context.groups];
  // Unlike for enterprises, where TenantAdmin permission grants super-admin access, AdministrationUser for agencies grants
  // access to Administration section only, i.e. it is not a super-admin access.
  // const admins = identities.filter((identity) => identity.permissions.includes(AgencyPermission.AdministrationUser));
  // if (admins.length) {
  //   return <Access>({
  //     isAdmin: true,
  //     isGranted: true,
  //     identities: admins
  //   });
  // }
  const granted = identities.filter(
    (identity) => permissions.every((permission) => identity.permissions.includes(permission))
  );
  if (granted.length) {
    return <Access>({
      isAdmin: false,
      isGranted: true,
      identities: granted
    });
  }

  return DENIED;
}

export function useUserAccess(
  permissions: UserPermissions,
  checkType: 'all' | 'any' = 'all',
): Access {
  const { userID, displayName } = useSelector((s) => s.app);
  const context = useUserContext();

  let granted: boolean = false;

  switch (checkType) {
    case 'all':
      granted = permissions.every((permission) => context.permissions.includes(permission));
      break;
    case 'any':
      granted = !!permissions.find((permission) => context.permissions.includes(permission));
      break;
  }

  if (granted) {
    return <Access>({
      isAdmin: false,
      isGranted: true,
      identities: [{
        id: userID,
        displayName: displayName,
        permissions: context.permissions
      }]
    });
  }

  return DENIED;
}

export function useAccess(permissions: Permissions): Access {
  const currentContext = useSelector((s) => s.app.currentContext);

  switch (currentContext.context) {
    case 'enterprise':
      return useEnterpriseAccess(permissions as EnterprisePermissions);
    case 'agency':
      return useAgencyAccess(permissions as AgencyPermissions);
    case 'user':
      return useUserAccess(permissions as UserPermissions);
  }

  return DENIED;
}
