/**
 * @generated SignedSource<<03446ba530abd5eb07510b9a8e0bda66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type NotificationKind = "other" | "timesheetApproved" | "timesheetEntry" | "timesheetRejected" | "timesheetReview";
export type NotificationStatus = "acknowledged" | "unread";
export type NotificationsSubscription$variables = {};
export type NotificationsSubscription$data = {
  readonly notification: {
    readonly acknowledgedAt: string | null;
    readonly actionUrl: string;
    readonly createdAt: string | null;
    readonly id: string;
    readonly kind: NotificationKind;
    readonly status: NotificationStatus;
    readonly subject: string;
    readonly text: string;
  };
};
export type NotificationsSubscription = {
  response: NotificationsSubscription$data;
  variables: NotificationsSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": "notification",
    "args": null,
    "concreteType": "Notification",
    "kind": "LinkedField",
    "name": "notifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acknowledgedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actionUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "kind",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsSubscription",
    "selections": (v0/*: any*/),
    "type": "GeneralSubscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3dcf55a29ee5ca5bc09258a4ed991405",
    "id": null,
    "metadata": {},
    "name": "NotificationsSubscription",
    "operationKind": "subscription",
    "text": "subscription NotificationsSubscription {\n  notification: notifications {\n    id\n    subject\n    status\n    text\n    acknowledgedAt\n    createdAt\n    actionUrl\n    kind\n  }\n}\n"
  }
};
})();

(node as any).hash = "e3ad6352aa97148170544c197d2360de";

export default node;
