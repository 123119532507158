/**
 * @generated SignedSource<<271ae4bcdd85faec470f133e7f16df28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationKind = "other" | "timesheetApproved" | "timesheetEntry" | "timesheetRejected" | "timesheetReview";
export type NotificationStatus = "acknowledged" | "unread";
export type NotificationsInitialQuery$variables = {
  status?: NotificationStatus | null;
};
export type NotificationsInitialQuery$data = {
  readonly user: {
    readonly notifications: {
      readonly continuationToken: string | null;
      readonly items: ReadonlyArray<{
        readonly acknowledgedAt: string | null;
        readonly actionUrl: string;
        readonly createdAt: string | null;
        readonly id: string;
        readonly kind: NotificationKind;
        readonly status: NotificationStatus;
        readonly subject: string;
        readonly text: string;
      }>;
      readonly totalCount: number;
    };
  };
};
export type NotificationsInitialQuery = {
  response: NotificationsInitialQuery$data;
  variables: NotificationsInitialQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserRoot",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "createdAt"
              },
              {
                "kind": "Literal",
                "name": "orderDir",
                "value": "descending"
              },
              {
                "kind": "Literal",
                "name": "skip",
                "value": 0
              },
              {
                "kind": "Variable",
                "name": "status",
                "variableName": "status"
              },
              {
                "kind": "Literal",
                "name": "take",
                "value": 10
              }
            ],
            "kind": "ObjectValue",
            "name": "filter"
          }
        ],
        "concreteType": "Notifications",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "continuationToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Notification",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subject",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acknowledgedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actionUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsInitialQuery",
    "selections": (v1/*: any*/),
    "type": "GeneralQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsInitialQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cb1f326562dc59c8558d99fc6646fb0c",
    "id": null,
    "metadata": {},
    "name": "NotificationsInitialQuery",
    "operationKind": "query",
    "text": "query NotificationsInitialQuery(\n  $status: NotificationStatus\n) {\n  user {\n    notifications(filter: {skip: 0, take: 10, orderBy: createdAt, orderDir: descending, status: $status}) {\n      continuationToken\n      totalCount\n      items {\n        id\n        subject\n        status\n        text\n        acknowledgedAt\n        createdAt\n        actionUrl\n        kind\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "410364ccd5f52d1d76150828428cdb92";

export default node;
