import { Autorenew } from '@mui/icons-material';

import SomethingWentWrongIcon from 'src/assets/img/something-went-wrong.svg?react';
import {
  TemplateContainer,
  ActionButtonContainer,
  ActionButton,
  MessageLayout,
} from './commons';

type UncaughtErrorNoticeProps = {
  children?: never;
};

const LegacyUncaughtErrorNotice = (_: UncaughtErrorNoticeProps) => {
  const handleRefresh = () => {
    location.reload();
  };

  return (
    <TemplateContainer>
      <MessageLayout
        headerTitle="Something went wrong"
        headerImage={<SomethingWentWrongIcon/>}
        message="We are working on fixing the problem. Please try again."
        footer={(
          <ActionButtonContainer>
            <ActionButton
              variant="contained"
              size="large"
              onClick={handleRefresh}>
              <Autorenew sx={{
                marginRight: 1.25,
              }}/>
              Refresh page
            </ActionButton>
          </ActionButtonContainer>
        )}/>
    </TemplateContainer>
  );
};

export default LegacyUncaughtErrorNotice;
