import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Layout from '@oncore/ui/Layout';
import ChromeTopBar from './ChromeTopBar';
import ChromeSideBar from './ChromeSideBar';
import { OncoreFlexLayout } from 'src/components/chrome';
import { getAppStateSelector } from 'src/store/selectors';

const StyledLayout = styled(Layout)`
  height: 100vh;
  width: 100vw;
`;

const Chrome = () => {
  const { isUntitledUI } = useSelector(getAppStateSelector());

  if (isUntitledUI) {
    return (
      <StyledLayout
        product="omp"
        topbar={<ChromeTopBar />}
        sidebar={<ChromeSideBar />}
        content={<Outlet />}
      />
    );
  }

  return (
    <OncoreFlexLayout/>
  );
};

export default Chrome;
