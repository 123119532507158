import { css as r } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-C9VCECeM.js";
import { getSpacings as o, getPaletteForeground as e, getPaletteBackground as t, getPaletteText as a } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-B6kdoens.js";
import "../../CssBaseline-CQMfs2gH.js";
const g = {
  root: r`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    gap: 8px;

    padding: ${o("md")} ${o("lg")};
    cursor: pointer;

    border: none;
    border-radius: 6px;

    color: ${e("quaternary.default")};
    background-color: ${t("primary.default")};

    text-decoration: none;

    span {
      color: ${a("secondary.default")};
    }
  `,
  active: r`
    color: ${e("quaternary.hover")};
    background-color: ${t("primary.hover")};

    span {
      color: ${a("secondary.hover")};
    }
  `
};
export {
  g as sideBarItemStyles
};
