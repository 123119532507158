import { css as l } from "styled-components";
import { r as t } from "../../polished.esm-B6VUs5va.js";
import "../Theme/themes.es.js";
import "../../palettes-C9VCECeM.js";
import { getSpacings as d, getPaletteText as $, getPaletteColors as r, getPaletteBorder as c, getPaletteBackground as a, getPaletteBase as e } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-B6kdoens.js";
import "../../CssBaseline-CQMfs2gH.js";
const u = {
  xs: l`
    padding: 7px ${d("lg")};
    gap: ${d("xs")};
  `,
  sm: l`
    padding: ${d("md")} ${d("lg")};
    gap: ${d("xs")};
  `,
  md: l`
    padding: 10px 14px;
    gap: ${d("xs")};
  `,
  lg: l`
    padding: 10px ${d("xl")};
    gap: ${d("sm")};
  `,
  xl: l`
    padding: ${d("lg")} 18px;
    gap: ${d("sm")};
  `,
  "2xl": l`
    padding: ${d("xl")} 22px;
    gap: 10px;
  `
}, m = {
  xs: l`
    height: 16px;
    width: 16px;
  `,
  sm: l`
    height: 20px;
    width: 20px;
  `,
  md: l`
    height: 20px;
    width: 20px;
  `,
  lg: l`
    height: 20px;
    width: 20px;
  `,
  xl: l`
    height: 20px;
    width: 20px;
  `,
  "2xl": l`
    height: 24px;
    width: 24px;
  `
}, k = {
  contained: l`
    color: ${$("primary.brand")};
    border: 1px solid ${(o) => r(o.$color)(o)[600]};
    background-color: ${(o) => r(o.$color)(o)[600]};

    &:hover, &:active {
      border: 1px solid ${(o) => r(o.$color)(o)[700]};
      background-color: ${(o) => r(o.$color)(o)[700]};
    }

    &:focus {
      box-shadow: 0px 0px 0px 4px ${(o) => t(r(o.$color)(o)[400], 0.24)};
    }

    &:disabled {
      border-color: ${c("disabled.subtle")};
      background-color: ${a("disabled.default")};
    }
  `,
  outlined: l`
    color: ${(o) => r(o.$color)(o)[700]};
    background-color: ${e("white")};
    border: 1px solid ${(o) => r(o.$color)(o)[300]};
    
    &:hover, &:active {
      background-color: ${(o) => r(o.$color)(o)[50]};
    }

    &:focus {
      box-shadow: 0px 0px 0px 4px ${(o) => t(r(o.$color)(o)[400], 0.24)};
    }

    &:disabled {
      border-color: ${c("disabled.subtle")};
      background-color: ${a("disabled.default")};
    }
  `,
  text: l`
    border: none;
    padding: 0;

    color: ${(o) => r(o.$color)(o)[700]};
    background-color: ${e("transparent")};

    &:hover, &:active {
      color: ${(o) => r(o.$color)(o)[800]};
    }

    &:focus {
      color: ${(o) => r(o.$color)(o)[800]};
    }
    
  `,
  ghost: l`
    border: none;
    
    color: ${(o) => r(o.$color)(o)[700]};
    background-color: ${e("transparent")};

    &:hover, &:active {
      color: ${(o) => r(o.$color)(o)[800]};
      background-color: ${(o) => r(o.$color)(o)[50]};
    }

    &:focus {
      background-color: ${(o) => r(o.$color)(o)[25]};
      box-shadow: 0px 0px 0px 4px ${(o) => t(r(o.$color)(o)[400], 0.24)};
    }
    
    &:disabled {
      background-color: ${e("transparent")};
    }
  `
};
export {
  m as iconSizeStyles,
  u as sizeStyles,
  k as variantStyles
};
