import { createContext } from 'react';

export type AppTitleContext = {
  title: string;
  setTitle: (title: string) => void;
};

const AppTitleContext = createContext<AppTitleContext>({
  title: '',
  setTitle: () => console.error('[AppTitle.setTitle] Operation is invoked prior initialization'),
});

export default AppTitleContext;
