import { useMemo } from 'react';
import {
  ParentTopMenuItem,
  SubMenuItem,
} from 'src/components/chrome';
import {
  SideBarParentItem,
} from '@oncore/ui/SideBar';
import ChromeSideBarItem from './ChromeSideBarItem';
import {
  useMenuItemVisibility,
  useIsParentMenuItemActive,
} from 'src/components/chrome/hooks';

type ChromeSideBarParentItemProps = {
  menuItem: ParentTopMenuItem;
};

const ChromeSideBarParentItem = (props: ChromeSideBarParentItemProps) => {
  const { menuItem } = props;
  const {
    spike,
    children,
  } = menuItem;
  const isMenuItemVisible = useMenuItemVisibility(menuItem);
  const title = typeof menuItem.title === 'function' ? menuItem.title() : menuItem.title;
  const subMenuList = useMemo(() => {
    return children.reduce((accItems, subMenuItem) => {

      if (subMenuItem.type === 'div') {
        return accItems;
      }

      return [
        ...accItems,
        subMenuItem,
      ];
    }, [] as SubMenuItem[]);
  }, [children]);
  const isActive = useIsParentMenuItemActive(subMenuList);

  if (!isMenuItemVisible) {
    return null;
  }

  return (
    <SideBarParentItem
      text={title}
      icon={spike.icon ? {
        variant: spike.icon,
      } : undefined}
      defaultOpen={isActive}>
      {
        subMenuList.map((subMenuItem) => (
          <ChromeSideBarItem
            key={subMenuItem.id}
            menuItem={subMenuItem}/>
        ))
      }
    </SideBarParentItem>
  );
};

export default ChromeSideBarParentItem;
