/**
 * @generated SignedSource<<0f3fb66c0f33c67ca833f7253f382cc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProfileEmailQuery$variables = {};
export type ProfileEmailQuery$data = {
  readonly user: {
    readonly profile: {
      readonly email: string | null;
    };
  };
};
export type ProfileEmailQuery = {
  response: ProfileEmailQuery$data;
  variables: ProfileEmailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserRoot",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": "profile",
        "args": null,
        "concreteType": "UserProfileEx",
        "kind": "LinkedField",
        "name": "profileEx",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileEmailQuery",
    "selections": (v0/*: any*/),
    "type": "GeneralQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileEmailQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c92c562cdc5474a2ff3715c6cab88998",
    "id": null,
    "metadata": {},
    "name": "ProfileEmailQuery",
    "operationKind": "query",
    "text": "query ProfileEmailQuery {\n  user {\n    profile: profileEx {\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "920bdd09f187c8a9cc24242a77c0824f";

export default node;
