/**
 * @generated SignedSource<<aa4b27bd93e970347963bb888cb3aff9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationKind = "other" | "timesheetApproved" | "timesheetEntry" | "timesheetRejected" | "timesheetReview";
export type NotificationStatus = "acknowledged" | "unread";
export type NotificationsFurtherQuery$variables = {
  continuationToken: string;
};
export type NotificationsFurtherQuery$data = {
  readonly user: {
    readonly notifications: {
      readonly continuationToken: string | null;
      readonly items: ReadonlyArray<{
        readonly acknowledgedAt: string | null;
        readonly actionUrl: string;
        readonly createdAt: string | null;
        readonly id: string;
        readonly kind: NotificationKind;
        readonly status: NotificationStatus;
        readonly subject: string;
        readonly text: string;
      }>;
    };
  };
};
export type NotificationsFurtherQuery = {
  response: NotificationsFurtherQuery$data;
  variables: NotificationsFurtherQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "continuationToken"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserRoot",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "continuationToken",
            "variableName": "continuationToken"
          }
        ],
        "concreteType": "Notifications",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "continuationToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Notification",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subject",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acknowledgedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actionUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsFurtherQuery",
    "selections": (v1/*: any*/),
    "type": "GeneralQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsFurtherQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8b0ddaf947ddcce2bce48152d44a9c79",
    "id": null,
    "metadata": {},
    "name": "NotificationsFurtherQuery",
    "operationKind": "query",
    "text": "query NotificationsFurtherQuery(\n  $continuationToken: String!\n) {\n  user {\n    notifications(continuationToken: $continuationToken) {\n      continuationToken\n      items {\n        id\n        subject\n        status\n        text\n        acknowledgedAt\n        createdAt\n        actionUrl\n        kind\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e4e61a9f13eb5be0a7740a7a25b4413";

export default node;
