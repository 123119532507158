import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';

import { Environment } from './utils';

const initialState: Environment = {
  version: '',
  api: '',
  auth: {
    clientID: '',
    audience: '',
    domain: '',
    tenant: '',
  },
  amplitude: {
    key: '',
  },
  logs: {
    token: '',
  },
  rum: {
    appID: '',
    token: '',
  },
  lightboxJS: {
    key: '',
  },
  isReady: false,
};

export type SetEnvironmentPayload = Environment;

export const setEnvironmentRoutine = createRoutine('SET_ENVIRONMENT', {
  request: (payload: SetEnvironmentPayload) => payload,
});

const environmentSlice = createSlice({
  name: 'environment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setEnvironmentRoutine.REQUEST, (_state, action: PayloadAction<SetEnvironmentPayload>) => {
      return {
        ...action.payload,
        isReady: true,
      };
    })
      .addDefaultCase(() => {});
  }
});

export default environmentSlice.reducer;
