import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, alpha, Typography, Theme, SxProps } from '@mui/material';
import { useDispatch, } from 'src/hooks';
import { setMenuOpen } from 'src/store/appSlice';
import {
  isMenuItemChildren,
  isMenuItemClick,
  isMenuItemHref,
  isSubMenuItem,
  isTopMenuItem,
  MenuItem,
  MenuItemChildren,
  SubMenuItem,
  TopMenuItem
} from './menu';
import OncoreFlexMenuRow from './OncoreFlexMenuRow';
import { NoChildrenProp } from '../common';
import { useMenuItemVisibility } from './hooks';
import { TrackedButton } from '@oncore/shared';

const styleNormal: SxProps<Theme> = (theme: Theme) => ({
  borderRadius: 0,
  padding: 0,
  height: '42px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  color: theme.palette.primary.contrastText,
  fontWeight: theme.typography.fontWeightRegular,
  '&.depth-0': {
    fontWeight: theme.typography.fontWeightMedium
  }
});

const styleActive: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: alpha(theme.palette.primary.contrastText, 0.23)
});

export type Props = NoChildrenProp<{
  goSub?: (item: MenuItem & MenuItemChildren) => void;
  item: TopMenuItem | SubMenuItem;
  depth?: number;
}>;

const OncoreFlexMenuMobileItem: React.FC<Props> = (props) => {
  const {
    item,
    depth = 0,
    goSub,
  } = props;
  const isMenuItemVisibile = useMenuItemVisibility(item);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sub = isSubMenuItem(item);
  const Icon = (isTopMenuItem(item) ? (<item.icon {...item.iconProps} />) : null);
  const title = typeof item.title === 'function' ? item.title() : item.title;

  const typo = (
    <Box ml={2 + 2 * depth} mr={2} alignSelf="center">
      <Typography variant={sub ? 'body2' : 'h5'}>
        {title}
      </Typography>
    </Box>
  );

  const component = isMenuItemHref(item) ? (
    <TrackedButton
      trackedName={title}
      sx={(theme) => ({
        ...styleNormal(theme),
        ...(location.pathname === item.href ? styleActive(theme) : {})
      })}
      href={item.href}
      onClick={(e) => {
        e.preventDefault();
        dispatch(setMenuOpen(false));
        navigate(item.href);
      }}
    >
      {typo}
    </TrackedButton>
  ) : isMenuItemClick(item) ? (
    <TrackedButton
      trackedName={title}
      onClick={item.onClick}
      sx={styleNormal}
    >
      {typo}
    </TrackedButton>
  ) : isMenuItemChildren(item) ? (
    <TrackedButton
      trackedName={title}
      onClick={goSub ? () => goSub(item) : undefined}
      sx={styleNormal}
    >
      {typo}
    </TrackedButton>
  ) : undefined;

  const content = (
    <>
      {component}
    </>
  );

  const ren = (
    <OncoreFlexMenuRow icon={Icon}>
      {content}
    </OncoreFlexMenuRow>
  );

  return isMenuItemVisibile ? ren : (<></>);
};

export default OncoreFlexMenuMobileItem;
