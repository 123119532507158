import { QueryClient } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import axios from 'axios';

const STALE_TIME_IN_MS = 0;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME_IN_MS,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const graphQLClient = new GraphQLClient('');

export const axiosClient = axios.create({});
