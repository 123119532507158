import {
  PropsWithChildren,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { getEnvironmentSelector } from 'src/state/environment/selectors';
import { setEnvironmentRoutine } from 'src/state/environment/reducer';
import OncoreLoader from '@oncore/ui/shared/OncoreLoader';

export type LoaderProps = PropsWithChildren;

function Loader(props: LoaderProps) {

  const { isReady } = useSelector(getEnvironmentSelector());

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isReady) {
      dispatch(setEnvironmentRoutine.trigger());
    }
  }, []);

  if (!isReady) {
    return (
      <OncoreLoader 
        variant="splash"
        loadingText="Loading environment settings..."/>
    );
  }

  return props.children;
}

export default Loader;
