import type { FC } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { Toolbar, useTheme, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { OncoreFlexHeader, HamburgerButton } from '@oncore/shared';
import { useDispatch, useSelector } from 'src/hooks';
import { setMenuOpen } from 'src/store/appSlice';
import OncoreFlexMenu from './OncoreFlexMenu';
import OncoreFlexHeaderAccount from './OncoreFlexHeaderAccount';
import OncoreFlexHeaderNotifications from './OncoreFlexHeaderNotifications';
import SupportHeaderItem from './OncoreFlexHeaderSupport';
import HolidayBanner from '../banner/HolidayBanner';

type Props = {
  children?: never;
};

const Root = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
const MobileMenuButton = styled.div`
  width: 70px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  @media (min-width: 1280px) {
    display: none;
  }
`;
const Content = styled.div<{
  $backgroundColor: string;
  $offsetTop: number;
}>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: ${(p) => `${p.$offsetTop - 8}px`};
  background-color: ${(p) => p.$backgroundColor};
  @media (min-width: 1280px) {
    padding-left: 256px;
    padding-top: ${(p) => `${p.$offsetTop}px`};
  }
`;

const OncoreFlexLayout: FC<Props> = () => {
  const dispatch = useDispatch();
  const { isMenuOpen } = useSelector((s) => s.app);
  const theme = useTheme();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [offsetTop, setOffsetTop] = useState(0);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      const clientHeight = headerRef.current?.clientHeight || 0;
      if (clientHeight !== offsetTop) {
        setOffsetTop(clientHeight);
      }
    });

    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [headerRef.current, offsetTop]);

  return (
    <Root>
      <OncoreFlexHeader>
        <div ref={headerRef}>
          <HolidayBanner/>
          <Toolbar disableGutters>
            <MobileMenuButton>
              <HamburgerButton
                trackedName="Open side menu"
                open={isMenuOpen}
                onClick={() => dispatch(setMenuOpen(!isMenuOpen))}
              />
            </MobileMenuButton>
            <Stack
              flex={1}
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end">
              <OncoreFlexHeaderNotifications />
              <SupportHeaderItem/>
              <OncoreFlexHeaderAccount />
            </Stack>
          </Toolbar>
        </div>
      </OncoreFlexHeader>
      <OncoreFlexMenu offsetTop={offsetTop}/>
      <Content $backgroundColor={theme.palette.background.default} $offsetTop={offsetTop}>
        <Outlet />
      </Content>
    </Root>
  );
};

export default OncoreFlexLayout;
