import React, { useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useSelector } from 'src/hooks';
import { getGeneralRoutes } from './getGeneralRoutes';
import { getProtectedRoutes } from './getProtectedRoutes';
import getContextDependentRoutes from './getContextDependentRoutes';
import AuthGuard from './AuthGuard';
import ContextGuard from './ContextGuard';
import { getAppStateSelector } from 'src/store/selectors';
import Chrome from 'src/spike/Chrome';
import AppLoader from 'src/spike/AppLoader';

export type Props = {
  children?: never;
};

export const OncoreFlexRouter = (_: Props) => {
  const {
    currentContext,
    contexts,
    isBankingEnabled,
    isCompanyDetailsEnabled,
  } = useSelector(getAppStateSelector());
  const contextDependentRoutes = useMemo(() => {
    return getContextDependentRoutes({
      currentContext,
      contexts,
    });
  }, [currentContext, contexts]);

  const authenticationGuardRoutes = useMemo(() => {
    return currentContext.context === 'loading' ? [{
      path: '*',
      element: (<AuthGuard />)
    }] : [{
      path: '*',
      element: (
        <React.Suspense fallback={<AppLoader />}>
          <Chrome />
          <ContextGuard />
        </React.Suspense>
      )
    }];
  }, [currentContext.context]);

  const router = useMemo(() => {
    return  createBrowserRouter([
      ...getGeneralRoutes(),
      ...getProtectedRoutes({
        isBankingEnabled,
        isCompanyDetailsEnabled,
      }),
      {
        element: (<AuthGuard />),
        children: contextDependentRoutes,
      },
      ...authenticationGuardRoutes,
    ]);
  }, [
    contextDependentRoutes,
    authenticationGuardRoutes,
    isBankingEnabled,
    isCompanyDetailsEnabled,
  ]);

  return (<RouterProvider router={router} />);
};

export default OncoreFlexRouter;
