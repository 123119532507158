import { j as r } from "./jsx-runtime-B6kdoens.js";
import { ThemeProvider as m } from "styled-components";
import { DEFAULT_LIGHT_THEME as i } from "./components/Theme/themes.es.js";
import { C as t } from "./CssBaseline-CQMfs2gH.js";
const a = (e) => {
  const {
    theme: o = i,
    children: s
  } = e;
  return /* @__PURE__ */ r.jsxs(m, { theme: o, children: [
    /* @__PURE__ */ r.jsx(t, {}),
    s
  ] });
};
export {
  a as T
};
