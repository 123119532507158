import React from 'react';
import { Settings } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'src/hooks';
import { NoChildrenProp as NoProps } from '../common';
import { MENU_ITEM_TYPE_TOP, TopMenuItem } from './menu';
import links from './links';
import OncoreFlexMenuMobileItem from './OncoreFlexMenuMobileItem';
import { setMenuOpen } from 'src/store/appSlice';

const OncoreFlexMenuMobileProfile: React.FC<NoProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const item: TopMenuItem = {
    contextType: null,
    id: 'profile',
    type: MENU_ITEM_TYPE_TOP,
    icon: Settings,
    title: 'Profile',
    onClick: () => {
      dispatch(setMenuOpen(false));
      navigate(links.user.profile.view);
    },
    products: null,
    children: [],
    spike: {
      to: links.user.profile.view,
    },
  };

  return (
    <OncoreFlexMenuMobileItem {...props} item={item} />
  );
};

export default OncoreFlexMenuMobileProfile;
