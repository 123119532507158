/**
 * @generated SignedSource<<eece6c7b4788437a923142c7bad4964f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationKind = "other" | "timesheetApproved" | "timesheetEntry" | "timesheetRejected" | "timesheetReview";
export type NotificationStatus = "acknowledged" | "unread";
export type NotificationsMarkAsReadMutation$variables = {
  notificationID: string;
};
export type NotificationsMarkAsReadMutation$data = {
  readonly notification: {
    readonly acknowledgedAt: string | null;
    readonly actionUrl: string;
    readonly createdAt: string | null;
    readonly id: string;
    readonly kind: NotificationKind;
    readonly status: NotificationStatus;
    readonly subject: string;
    readonly text: string;
  };
};
export type NotificationsMarkAsReadMutation = {
  response: NotificationsMarkAsReadMutation$data;
  variables: NotificationsMarkAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "notificationID"
  }
],
v1 = [
  {
    "alias": "notification",
    "args": [
      {
        "kind": "Variable",
        "name": "notificationID",
        "variableName": "notificationID"
      }
    ],
    "concreteType": "Notification",
    "kind": "LinkedField",
    "name": "markNotificationAsRead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acknowledgedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actionUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "kind",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsMarkAsReadMutation",
    "selections": (v1/*: any*/),
    "type": "GeneralMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsMarkAsReadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aa644f925302d19cc206de959ea4661b",
    "id": null,
    "metadata": {},
    "name": "NotificationsMarkAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationsMarkAsReadMutation(\n  $notificationID: ID!\n) {\n  notification: markNotificationAsRead(notificationID: $notificationID) {\n    id\n    subject\n    status\n    text\n    acknowledgedAt\n    createdAt\n    actionUrl\n    kind\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae668f1750546a5b50bfff029c241e12";

export default node;
