import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSetAnalyticsUserProperties } from '@oncore/shared';
import { switchContext } from 'src/store/appSlice';
import { useDispatch, useSelector } from 'src/hooks';
import { AppScreenLoader } from 'src/spike/AppLoader';

export type Props = {
  children?: never;
};

export const UserContextSwitch = (_: Props) => {

  const { currentContext } = useSelector((s) => s.app);

  const dispatch = useDispatch();

  const setAnalyticsUserProperties = useSetAnalyticsUserProperties();

  useEffect(() => {
    if (currentContext.context !== 'user') {
      dispatch(switchContext({
        context: 'user'
      }));
    }
    if (currentContext.context === 'agency' || currentContext.context === 'enterprise') {
      setAnalyticsUserProperties({
        tenant: currentContext.displayName,
        context: currentContext.context,
        features: currentContext.features,
        permissions: currentContext.permissions
      });
    } else if (currentContext.context === 'user') {
      setAnalyticsUserProperties({
        tenant: 'none',
        context: currentContext.context,
        features: currentContext.features,
        permissions: currentContext.permissions
      });
    }
  }, [currentContext]);

  return currentContext.context === 'user'
    ? <Outlet />
    : <AppScreenLoader />;
};

export default UserContextSwitch;
