import { css as i } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-C9VCECeM.js";
import { getSpacings as t } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-B6kdoens.js";
import "../../CssBaseline-CQMfs2gH.js";
import { variantStyles as p } from "../Button/styles.es.js";
const o = p, e = {
  xs: i`
    padding: ${t("md")};
    
    > svg {
      width: 16px;
      height: 16px;
    }
  `,
  sm: i`
    padding: ${t("md")};

    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  md: i`
    padding: 10px;

    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  lg: i`
    padding: ${t("lg")};

    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  xl: i`
    padding: 14px;
    
    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  "2xl": i`
    padding: ${t("xl")};

    > svg {
      width: 24px;
      height: 24px;
    }
  `
};
export {
  e as sizeStyles,
  o as variantStyles
};
