import { ErrorBoundary } from '@oncore/shared';
import { PropsWithChildren } from 'react';
import UncaughtErrorNotice from 'src/spike/Notice/UncaughtErrorNotice';

type OncoreErrorBoundaryProps = PropsWithChildren<{}>;

const OncoreErrorBoundary = (props: OncoreErrorBoundaryProps) => {

  return (
    <ErrorBoundary ErrorDisplay={() => <UncaughtErrorNotice/>}>
      {props.children}
    </ErrorBoundary>
  );
};

export default OncoreErrorBoundary;
