import React from 'react';
import { fetchQuery } from 'relay-runtime';
import { Box, Typography } from '@mui/material';
import { SwipeableList } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { CardsView } from '@oncore/shared';
import environment from 'src/environment';
import { terminology } from 'src/global';
import MiriamNotifications from 'src/assets/img/miriam-notifications.svg?react';
import MiriamNoNotifications from 'src/assets/img/miriam-no-notifications.svg?react';
import {
  Action,
  Filter,
  FurtherData,
  InitialData,
  Store,
  NotificationData
} from '../reducer';
import {
  notificationsInitialQuery,
  NotificationsInitialQuery,
  notificationsFurtherQuery,
  NotificationsFurtherQuery
} from '../relay';
import Notification from './Notification';
import Skeleton from './NotificationSkeleton';

const styles = {
  container: (hasNotifications: boolean) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: hasNotifications ? 'space-between' : 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    pt: 1,
    '& .swipeable-list': {
      overflowY: 'visible',
      height: 'auto',
      flex: hasNotifications ? '1 1' : '0'
    }
  }),
  miriamContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pb: 2,
    pt: 3,
    '& svg': {
      height: 115,
    },
    '& p': {
      marginTop: '20px',
      fontSize: '14px'
    }
  }
};

export type Props = {
  state: Store;
  dispatch: React.Dispatch<Action>;
  children?: never;
};

const Content: React.FC<Props> = (props) => {
  const {
    state,
    dispatch
  } = props;

  const {
    filter,
    continuationToken,
    items
  } = state;

  const loading = !state.loaded;

  const loadInitial = async (x: Filter, signal: AbortSignal): Promise<InitialData> => {
    const response = await fetchQuery<NotificationsInitialQuery>(environment, notificationsInitialQuery, {
      status: x.statusOnlyUnread ? 'unread' : undefined
    }, {
      networkCacheConfig: {
        metadata: {
          signal
        }
      }
    }).toPromise();
    return response!.user.notifications;
  };

  const loadFurther = async (x: string, signal: AbortSignal): Promise<FurtherData> => {
    const response = await fetchQuery<NotificationsFurtherQuery>(environment, notificationsFurtherQuery, {
      continuationToken: x
    }, {
      networkCacheConfig: {
        metadata: {
          signal
        }
      }
    }).toPromise();
    return response!.user.notifications;
  };

  const hasNotifications = !!items.length;

  return (
    <Box sx={styles.container(hasNotifications || loading)}>
      <SwipeableList>
        <CardsView
          filter={filter}
          loading={loading}
          spacing={1}
          cardsPerRow={1}
          loadingCard={<Skeleton />}
          continuationToken={continuationToken}
          loadInitial={loadInitial}
          loadFurther={loadFurther}
          dispatch={dispatch}
        >
          {items && items.map((item) => (
            <Notification
              key={item.id}
              item={item}
              onUpdate={(updateItem: NotificationData) => {
                if (state.filter.statusOnlyUnread && updateItem.status === 'acknowledged') {
                  dispatch({
                    type: 'REMOVE_ITEM',
                    payload: updateItem
                  });
                } else {
                  dispatch({
                    type: 'UPDATE_ITEM',
                    payload: updateItem
                  });
                }
              }}
            />
          ))}
        </CardsView>
      </SwipeableList>
      {!loading && (
        <Box sx={styles.miriamContainer}>
          {items.length ? (
            <MiriamNotifications />
          ) : (
            <MiriamNoNotifications />
          )}
          <Typography>
            {hasNotifications
              ? terminology.notifications.thatsAll
              : terminology.notifications.youReadAll
            }
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Content;
