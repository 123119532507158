import React from 'react';
import { useTheme } from '@mui/material';
import { useSelector } from 'src/hooks';
import { NoChildrenProp } from '../common';
import { DivMenuItem } from './menu';
import OncoreFlexMenuRow from './OncoreFlexMenuRow';

export type Props = NoChildrenProp<{
  item: DivMenuItem;
}>;

const OncoreFlexMenuDivider: React.FC<Props> = ({ item }) => {
  const theme = useTheme();

  const { currentContext } = useSelector((s) => s.app);

  const isSuitableContext = item.contextType === null
    || item.contextType === undefined
    || item.contextType === currentContext.context;

  return isSuitableContext
    ? (<OncoreFlexMenuRow height={theme.spacing(2)} />)
    : (<></>);
};

export default OncoreFlexMenuDivider;
