import {
  AnalyticsEvents,
  useAnalyticsUpdater,
} from '@oncore/shared';
import { useDispatch } from 'src/hooks';
import { logout } from 'src/store/appSlice';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export type Props = {
  children?: never;
};

const Logout = (_: Props) => {

  const dispatch = useDispatch();

  const sendAnalyticsLogout = useAnalyticsUpdater(AnalyticsEvents.LOGOUT);

  const { logout: auth0Logout } = useAuth0();

  useEffect(() => {
    sendAnalyticsLogout();
    auth0Logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    }).then(() => dispatch(logout()));
  }, []);

  return null;
};

export default Logout;
