import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  matchPath,
  useLocation,
} from 'react-router-dom';

import { getAppStateSelector } from 'src/store/selectors';
import {
  TopMenuItem,
  SubMenuItem,
  TopMenuItems,
  isMenuItemPermissions,
  isMenuItemFeatures,
  UserLevelMenu,
  AgencyLevelMenu as getAgencyLevelMenu,
  EnterpriseLevelMenu as getEnterpriseLevelMenu,
  SubMenuItems,
} from './menu';
import {
  useAccess,
  useFeatures,
} from 'src/hooks';

export const useMenuItemVisibility = (menuItem: (TopMenuItem | SubMenuItem)) => {
  const { currentContext } = useSelector(getAppStateSelector());
  const permissions = isMenuItemPermissions(menuItem) ? menuItem.permissions : [];
  const features = isMenuItemFeatures(menuItem) ? menuItem.features || [] : [];
  const isSuitableContext = useMemo(() => {
    return menuItem.contextType === null
      || menuItem.contextType === undefined
      || menuItem.contextType === currentContext.context;
  }, [currentContext.context, menuItem.contextType]);
  const isSuitableProduct = useMemo(() => {
    return menuItem.products === null || (
      (
        currentContext.context === 'user' ||
        currentContext.context === 'enterprise' ||
        currentContext.context === 'agency'
      ) && menuItem.products.includes(currentContext.product)
    );
  }, [
    currentContext,
    menuItem.products,
  ]);
  const { isGranted } = useAccess(permissions || []);
  const isEnabled = useFeatures(features);

  return isSuitableContext && isSuitableProduct && isGranted && isEnabled;
};

export const useMenuList = () => {
  const { currentContext } = useSelector(getAppStateSelector());
  const menuList = useMemo<TopMenuItems>(() => {
    switch (currentContext.context) {
      case 'user':
        return UserLevelMenu;
      case 'agency':
        return getAgencyLevelMenu(currentContext.id);
      case 'enterprise':
        return getEnterpriseLevelMenu(currentContext.id);
      default:
        return [];
    }
  }, [currentContext.context]);

  return menuList;
};

export const useIsParentMenuItemActive = (subMenuList: SubMenuItems) => {
  const location = useLocation();
  const isActive = useMemo(() => {
    const activeChild = subMenuList.find((item) => {
      if ('href' in item) {
        const result = matchPath({
          path: item.href,
          end: false,
        }, location.pathname);

        return Boolean(result);
      }

      return false;
    });

    return Boolean(activeChild);
  }, [subMenuList, location.pathname]);

  return isActive;
};
