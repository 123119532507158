import { useSelector } from 'react-redux';

import { SomethingWentWrong } from '@oncore/ui/ErrorPages';

import { getAppStateSelector } from 'src/store/selectors';
import LegacyUncaughtErrorNotice from 'src/components/notice/LegacyUncaughtErrorNotice';

type UncaughtErrorNoticeProps = {
  children?: never;
};

const UncaughtErrorNotice = (_: UncaughtErrorNoticeProps) => {
  const handleRefresh = () => {
    location.reload();
  };
  const { isUntitledUI } = useSelector(getAppStateSelector());

  if (isUntitledUI) {
    return (
      <SomethingWentWrong
        onRefreshClick={handleRefresh}
      />
    );
  }

  return (
    <LegacyUncaughtErrorNotice/>
  );
};

export default UncaughtErrorNotice;
