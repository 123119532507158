import {
  Box,
  Typography,
  Theme,
  CircularProgress,
  Link
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckIcon from '@mui/icons-material/Check';
import { isMobile } from 'react-device-detect';
import { TrackedIconButton, useMutation } from '@oncore/shared';
import {
  LeadingActions,
  SwipeableListItem,
  SwipeAction,
} from 'react-swipeable-list';
import environment from 'src/environment';
import {
  markNotificationAsReadMutation,
  MarkNotificationsAsReadMutation,
  markNotificationAsUnreadMutation,
  MarkNotificationsAsUnreadMutation,
} from '../relay';
import {
  Notification as Item,
  NotificationData
} from '../reducer';
import NotificationIcon from './NotificationIcon';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    pr: 2,
    mb: 2,
    pl: 2,
  },
  statusContainer: {
    pt: '18px',
    pl: 1,
    pr: 1,
    width: '40px',
    height: '40px',
  },
  date: (theme: Theme) => ({
    color: theme.palette.grey[600],
    fontSize: '12px',
    marginBottom: 0.5
  }),
  subject: (theme: Theme) => ({
    marginBottom: 0.5,
    '& > a': {
      color: theme.palette.grey[800],
      fontSize: '16px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }),
  content: (theme: Theme) => ({
    '& > a': {
      ...theme.typography.body1,
      fontSize: '14px',
      color: theme.palette.grey[800],
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }),
  unread: {
    '& > a': {
      fontWeight: 600,
    }
  },
  changeStatusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end !important',
    pr: 2,
    backgroundColor: '#FBC94C',
    '& svg': {
      color: '#fff'
    }
  },
  iconContainer: {
    paddingTop: '12px',
    paddingRight: '8px',
  },
};

export type Props = {
  item: Item;
  onUpdate: (item: NotificationData) => void;
  children?: never;
};


const Notification = (props: Props) => {
  const {
    item,
    onUpdate
  } = props;
  const {
    subject,
    text,
    createdAt,
    status,
    actionUrl,
    kind
  } = item;

  const [markRead, readLoading] = useMutation<MarkNotificationsAsReadMutation>({
    graphql: markNotificationAsReadMutation,
    environment,
    variables: {
      notificationID: item.id
    },
    silent: true,
    onComplete: (response) => {
      onUpdate(response.notification);
    }
  });

  const [markUnead, unreadLoading] = useMutation<MarkNotificationsAsUnreadMutation>({
    graphql: markNotificationAsUnreadMutation,
    environment,
    variables: {
      notificationID: item.id
    },
    silent: true,
    onComplete: (response) => {
      onUpdate(response.notification);
    }
  });

  const handleClickStatus = () => {
    if (status === 'unread') {
      markRead();
    } else {
      markUnead();
    }
  };

  const notificationBody = (
    <Box sx={styles.container}>
      <Box sx={styles.iconContainer}>
        <NotificationIcon status={status} kind={kind} />
      </Box>
      <Box>
        {createdAt && (
          <Typography sx={styles.date}>
            {createdAt.fromNow().toString()}
          </Typography>
        )}
        <Typography sx={[styles.subject, status === 'unread' && styles.unread]}>
          <Link href={actionUrl}>{subject}</Link>
        </Typography>
        <Box sx={[styles.content, status === 'unread' && styles.unread]}>
          <Link href={actionUrl}>{text} </Link>
        </Box>
      </Box>
      <Box sx={styles.statusContainer}>
        {(readLoading || unreadLoading) ? (
          <CircularProgress size={16} sx={{ m: 1 }} />
        ) : (
          isMobile ? (
            <FiberManualRecordIcon
              sx={{ width: 16, height: 16, m: 1 }}
              color={status === 'unread' ? 'primary' : 'disabled'}
            />
          ) : (
            <TrackedIconButton
              onClick={handleClickStatus}
              trackedName={(status === 'unread') ? 'Mark read' : 'Mark unread'}
            >
              <FiberManualRecordIcon
                sx={{ width: 24, height: 24 }}
                color={status === 'unread' ? 'primary' : 'disabled'}
              />
            </TrackedIconButton>
          )
        )}
      </Box>
    </Box>
  );

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={handleClickStatus}>
        <Box sx={styles.changeStatusContainer}>
          <CheckIcon />
        </Box>
      </SwipeAction>
    </LeadingActions>
  );

  return isMobile ? (
    <SwipeableListItem
      leadingActions={leadingActions()}
      threshold={0.3}
    >
      {notificationBody}
    </SwipeableListItem>
  ) : (
    notificationBody
  );
};

export default Notification;
