import {
  Box,
  Menu,
  MenuProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useRef } from 'react';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import useDisclose from 'src/hooks/useDisclose';
import { useSelector } from '../../hooks';
import { TrackedIconButton, TrackedMenuItem } from '@oncore/shared';

const SupportMenu = (props: MenuProps) => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.up('sm'));
  const horizontalPosition = isMatched ? 'right' : 'center';

  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: horizontalPosition,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: horizontalPosition,
      }}
      {...props}
      />
  );
};

const linksForFreedom = [
  {
    label: 'Knowledge Base',
    url: 'https://support.oncoreflex.com/freedom',
  },
  {
    label: 'Contact Us',
    url: 'https://support.oncoreservices.com/hc/en-us/requests/new',
  },
];

const linksForEverythingElse = [
  {
    label: 'Knowledge Base',
    url: 'https://support.oncoreflex.com',
  },
  {
    label: 'Contact Us',
    url: 'https://support.oncoreservices.com/hc/en-us/requests/new',
  },
];

type OncoreFlexHeaderSupportProps = {
  children?: never;
};

function OncoreFlexHeaderSupport(_: OncoreFlexHeaderSupportProps) {
  const buttonRef = useRef(null);
  const disclose = useDisclose();

  const { context } = useSelector((s) => s.app.currentContext);

  const links = context === 'user'
    ? linksForFreedom
    : linksForEverythingElse;

  const handleClick = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
    disclose.onClose();
  };

  return (
    <Box>
      <TrackedIconButton
        trackedName="Help"
        ref={buttonRef}
        sx={{ color: 'white' }}
        size="large"
        onClick={disclose.onOpen}>
        <HelpOutlineOutlinedIcon/>
      </TrackedIconButton>
      <SupportMenu
        anchorEl={buttonRef.current}
        open={disclose.isOpen}
        onClose={disclose.onClose}
        >
        {
          links.map((link) => (
            <TrackedMenuItem
              key={link.label}
              onClick={() => handleClick(link.url)}
              trackedName="Help link"
            >
              {link.label}
            </TrackedMenuItem>
          ))
        }
      </SupportMenu>
    </Box>
  );
}

export default OncoreFlexHeaderSupport;
