import { css as h } from "styled-components";
const t = {
  xs: h`width: 24px; height: 24px;`,
  sm: h`width: 32px; height: 32px;`,
  md: h`width: 40px; height: 40px;`,
  lg: h`width: 48px; height: 48px;`,
  xl: h`width: 56px; height: 56px;`,
  xxl: h`width: 64px; height: 64px;`
}, i = {
  xs: h`
    width: 24px; 
    height: 24px;
    
    > svg {
      width: 16px;
      height: 16px;
    }
  `,
  sm: h`
    width: 32px; 
    height: 32px;

    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  md: h`
    width: 40px; 
    height: 40px;

    > svg {
      width: 24px;
      height: 24px;
    }
  `,
  lg: h`
    width: 48px; 
    height: 48px;

    > svg {
      width: 28px;
      height: 28px;
    }
  `,
  xl: h`
    width: 56px; 
    height: 56px;

    > svg {
      width: 32px;
      height: 32px;
    }
  `,
  xxl: h`
    width: 64px; 
    height: 64px;

    > svg {
      width: 32px;
      height: 32px;
    }
  `
};
export {
  t as avatar,
  i as avatarSizeStyles
};
