import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useEffect } from 'react';

import { getAppStateSelector } from 'src/store/selectors';
import { setTokenRoutine } from 'src/state/user/reducer';

const useLegacyStateTransition = () => {
  const appState = useSelector(getAppStateSelector());

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTokenRoutine.trigger(appState.token || ''));
  }, [appState.token]);

};

export default useLegacyStateTransition;
