import { useEffect, useState } from 'react';
import {
  Features,
  AgencyFeatures,
  EnterpriseFeatures,
  UserFeatures,
} from 'src/components/chrome';
import {
  useAgencyContext,
  useEnterpriseContext,
  useUserContext,
} from './useContext';
import { useSelector } from './useStore';
import { AgencyFeature, UserFeature, EnterpriseFeature } from '@oncore/shared';

export function useFeatures(target: Features): boolean {
  const [required] = useState<Features>(target);
  const [enabled, setEnabled] = useState<boolean>(false);

  const { currentContext } = useSelector((s) => s.app);

  useEffect(() => {
    if (currentContext.context === 'user') {
      return setEnabled(required.every((feature) => currentContext.features.includes(feature as UserFeature)));
    }
    if (currentContext.context === 'agency') {
      return setEnabled(required.every((feature) => currentContext.features.includes(feature as AgencyFeature)));
    }
    if (currentContext.context === 'enterprise') {
      return setEnabled(required.every((feature) => currentContext.features.includes(feature as EnterpriseFeature)));
    }
    return setEnabled(false);
  }, [required, currentContext]);

  return enabled;
}

export const useUserFeatures = (features: UserFeatures): boolean => {
  const context = useUserContext();
  return features.every((feature) => context.features.includes(feature));
};

export const useEnterpriseFeatures = (features: EnterpriseFeatures): boolean => {
  const context = useEnterpriseContext();
  return features.every((feature) => context.features.includes(feature));
};

export const useAgencyFeatures = (features: AgencyFeatures): boolean => {
  const context = useAgencyContext();
  return features.every((feature) => context.features.includes(feature));
};
