import { j as r } from "../../jsx-runtime-B6kdoens.js";
import s from "styled-components";
import { c as p } from "../../clsx.m-BMVuQnLY.js";
import "../Theme/themes.es.js";
import "../../palettes-C9VCECeM.js";
import { getPaletteBorder as o } from "../Theme/utils.es.js";
import "react";
import "../../CssBaseline-CQMfs2gH.js";
const c = "1024px", l = s.div`
  width: 100%;
  height: 100%;
  
  padding: 0;
  margin: 0;
    
  display: grid;
  
  grid-template-columns: 312px auto;
  grid-template-rows: 64px auto;
  grid-template-areas: "sidebar topbar" "sidebar content";

  @media (max-width: ${c}) {
    grid-template-columns: auto 56px;
    grid-template-rows: 64px auto;
    grid-template-areas: "topbar sidebar" "content content";
  }

  overflow: hidden;
  
  > div.sidebar-container {
    grid-area: sidebar;
    
    display: grid;

    overflow: hidden;

    border-right: 1px solid ${o("secondary")};
    
    box-sizing: border-box;

    @media (max-width: 1024px) {
      border-right: none;
      border-bottom: 1px solid ${o("secondary")};
      height: 64px;
    }
  }

  > div.topbar-container {
    grid-area: topbar;

    display: grid;

    overflow: hidden;

    border-bottom: 1px solid ${o("secondary")};
    
    height: 64px;

    box-sizing: border-box;
  }
  
  > div.content-container {
    grid-area: content;
    display: flex;
    overflow: auto;

    min-height: calc(100vh - 64px);
  }
`, w = ({ product: e, sidebar: t, topbar: i, content: a, className: d, ...n }) => /* @__PURE__ */ r.jsxs(l, { ...n, className: p(`product-${e}`, d), children: [
  /* @__PURE__ */ r.jsx("div", { className: "sidebar-container", children: t }),
  /* @__PURE__ */ r.jsx("div", { className: "topbar-container", children: i }),
  /* @__PURE__ */ r.jsx("div", { className: "content-container", children: a })
] });
export {
  w as default
};
