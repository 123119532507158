import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import styled from 'styled-components';

import AppLoader from 'src/spike/AppLoader';
import ScrollReset from '../ScrollReset';
import OncoreErrorBoundary from './OncoreErrorBoundary';
import Chrome from 'src/spike/Chrome';

const NonChromeContainer = styled.div`
  height: 100vh;
`;

export const innerChildRouteFor = (path: string, element: JSX.Element, children?: RouteObject[]): RouteObject => ({
  path,
  element: (
    <React.Suspense fallback={<AppLoader />}>
      <OncoreErrorBoundary>
        {element}
      </OncoreErrorBoundary>
    </React.Suspense>
  ),
  children
});

export const layoutFor = (element: JSX.Element, children?: RouteObject[]): RouteObject => ({
  element: (
    <React.Suspense fallback={<AppLoader />}>
      <OncoreErrorBoundary>
        {element}
      </OncoreErrorBoundary>
    </React.Suspense>
  ),
  children
});

export const childRouteFor = (path: string, element: JSX.Element, children?: RouteObject[], isNonChrome?:boolean): RouteObject => ({
  path,
  element: (
    <React.Suspense fallback={<AppLoader />}>
      <ScrollReset />
      <OncoreErrorBoundary>
        {isNonChrome ? <Outlet/> : <Chrome />}
      </OncoreErrorBoundary>
    </React.Suspense>
  ),
  children: [...(children || []), {
    index: true,
    element: (
      <React.Suspense fallback={isNonChrome ? (
        <NonChromeContainer>
          <AppLoader />
        </NonChromeContainer>
      ) : <AppLoader />}>
        <OncoreErrorBoundary>
          {element}
        </OncoreErrorBoundary>
      </React.Suspense>
    )
  }]
});
