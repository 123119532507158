import { PropsWithChildren } from 'react';

import AppTitle from './AppTitle';
import { useChromeProductName } from '../Chrome/hooks';

export type ConnectedAppTitleProps = PropsWithChildren;

const ConnectedAppTitle = (props: ConnectedAppTitleProps) => {
  const product = useChromeProductName();

  return (
    <AppTitle
      name="Oncore Services"
      product={product}>
      {props.children}
    </AppTitle>
  );
};

export default ConnectedAppTitle;
