import {
  put,
  takeLatest,
  call,
} from 'typed-redux-saga';

import { setEnvironmentRoutine } from './reducer';
import { getEnvironmentVariables } from './utils';
import {
  graphQLClient,
  axiosClient,
} from 'src/clients';

function* setEnvironment() {
  const environment = yield* call(getEnvironmentVariables);

  yield* put(setEnvironmentRoutine.request(environment));

  graphQLClient.setEndpoint(`${environment.api}/graphql/app`);

  axiosClient.defaults.baseURL = environment.api;

  yield* put(setEnvironmentRoutine.success());
}

export default function* environmentSagas() {
  yield* takeLatest(setEnvironmentRoutine.TRIGGER, setEnvironment);
}
