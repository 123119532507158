import {
  takeLatest,
  select,
  put,
} from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  graphQLClient,
  axiosClient,
} from 'src/clients';
import { setTokenRoutine } from './reducer';
import { getCurrentUserSelector } from './selectors';

function* setToken(action: PayloadAction<string>) {
  yield* put(setTokenRoutine.request(action.payload));

  yield* put(setTokenRoutine.success());
}

function* setClientsAuthorizationHeaders(){
  const { token } = yield* select(getCurrentUserSelector());

  const authValue = token ? `Bearer ${token}` : '';

  graphQLClient.setHeader('Authorization', authValue);

  axiosClient.defaults.headers.common.Authorization = authValue;
}

export default function* userSagas(){
  yield* takeLatest(setTokenRoutine.TRIGGER, setToken);
  yield* takeLatest(setTokenRoutine.SUCCESS, setClientsAuthorizationHeaders);
}
