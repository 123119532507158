export { default as HistoryIcon } from 'src/assets/img/history.svg?react';

export type IconProps = {
  color?: string;
};

/* eslint-disable max-len */

export const ExpenseClientPaidIcon = ({ color = '#000' }: IconProps) => {
  return (
    <svg width="24" height="12" viewBox="0 0 24 12" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0286 12L6.14286 9.49296V11.0986H0V0H8.82857L16.1143 2.70422C16.6286 2.89202 17.0619 3.19718 17.4143 3.61972C17.7667 4.04225 17.9429 4.64789 17.9429 5.43662H21.2C22 5.43662 22.6667 5.71831 23.2 6.28169C23.7333 6.84507 24 7.60563 24 8.56338V9.29577L15.0286 12ZM1.71429 9.40845H4.4V1.69014H1.71429V9.40845ZM14.9143 10.2535L22.2286 8.05634C22.1143 7.69953 21.9714 7.4554 21.8 7.32394C21.6286 7.19249 21.4286 7.12676 21.2 7.12676H15.2857C14.7143 7.12676 14.1857 7.0892 13.7 7.01408C13.2143 6.93897 12.7524 6.83568 12.3143 6.70423L10 6L10.6286 4.3662L12.7143 5.04225C13.1905 5.19249 13.6429 5.29577 14.0714 5.35211C14.5 5.40845 15.181 5.43662 16.1143 5.43662C16.1143 5.21127 16.0714 4.99061 15.9857 4.77465C15.9 4.55869 15.7524 4.40376 15.5429 4.30986L8.54286 1.69014H6.14286V7.71831L14.9143 10.2535Z" />
    </svg>
  );
};

export const NotificationOtherIcon = ({ color = '#000' }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </svg>
  );
};

export const NotificationTimesheetIcon = ({ color = '#000' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke={color} stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
      <polyline points="12 6 12 12 16 14"></polyline>
    </svg>
  );
};

export const CheckCircleIcon = ({ color = '#000' }: IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 4L12 14.01L9 11.01"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
