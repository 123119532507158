import React from 'react';
import { LogOut } from 'react-feather';
import { NoChildrenProp as NoProps } from '../common';
import { MENU_ITEM_TYPE_TOP, TopMenuItem } from './menu';
import OncoreFlexMenuMobileItem from './OncoreFlexMenuMobileItem';
import { useNavigate } from 'react-router-dom';
import links from './links';

const OncoreFlexMenuMobileLogout: React.FC<NoProps> = (props) => {
  const navigate = useNavigate();

  const item: TopMenuItem = {
    contextType: null,
    id: 'logout',
    type: MENU_ITEM_TYPE_TOP,
    icon: LogOut,
    title: 'Logout',
    onClick: () => navigate(links.logout),
    products: null,
    children: [],
    spike: {
      to: links.logout,
    },
  };

  return (
    <OncoreFlexMenuMobileItem {...props} item={item} />
  );
};

export default OncoreFlexMenuMobileLogout;
