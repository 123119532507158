import appReducer from '../store/appSlice';

import userReducer from './user/reducer';
import environmentReducer from './environment/reducer';

const reducer = {
  app: appReducer,
  environment: environmentReducer,
  user: userReducer,
};

export default reducer;
