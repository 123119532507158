import React, { Reducer, useEffect, useReducer } from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import { 
  styled,
  keyframes,
} from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';
import { useDispatch, useSelector } from 'src/hooks';
import { setMenuOpen } from 'src/store/appSlice';
import { NoChildrenProp } from '../common';
import {
  DivMenuItem,
  isDivMenuItem,
  isSubMenuItem,
  isTopMenuItem,
  MENU_ITEM_TYPE_TOP,
  MenuItem,
  MenuItemChildren,
  SubMenuItem,
  SubMenuItems,
  TopMenuItem,
  TopMenuItems
} from './menu';
import OncoreFlexMenuMobileItem from './OncoreFlexMenuMobileItem';
import OncoreFlexMenuDivider from './OncoreFlexMenuDivider';
import OncoreFlexMenuFill from './OncoreFlexMenuFill';
import OncoreFlexMenuMobileTenants from './OncoreFlexMenuMobileTenants';
import OncoreFlexMenuMobileProfile from './OncoreFlexMenuMobileProfile';
import OncoreFlexMenuMobileLogout from './OncoreFlexMenuMobileLogout';

const backEffect = keyframes`
  0% {
    left: -100vw;
    right: 100vw;
  }
  100% {
    left: 0vw;
    right: 0vw;
  }
`;
const subEffect = keyframes`
  0% {
    left: 0vw;
    right: 0vw
  }
  100% {
    left: -100vw;
    right: 100vw
  }
`;
const subToChildEffect = keyframes`
  0% {
    left: 100vw;
    right: -100vw;
  }
  100% {
    left: 0vw;
    right: 0vw;
  }
`;
const backToParentEffect = keyframes`
  0% {
    left: 0vw;
    right: 0vw;
  }
  100% {
    left: 100vw;
    right: -100vw;
  }
`;

const BackButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText
}));

const TopPane = styled(Box)({
  position: 'absolute',
  display: 'flex',
  flexFlow: 'column',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0
});

const SubPane = styled(Box)({
  position: 'absolute',
  display: 'flex',
  flexFlow: 'column',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0
});

type Keyframes = ReturnType<typeof keyframes>;

type RenderFunc = (animation: Keyframes | undefined) => React.ReactNode;

type State = {
  parents: RenderFunc[];
  current?: RenderFunc;
  child?: RenderFunc;
  parentAnimation?: Keyframes;
  currentAnimation?: Keyframes;
  childAnimation?: Keyframes;
};

const MOBILE_ACTION_SUB = 'sub';
const MOBILE_ACTION_BACK = 'back';
const MOBILE_ACTION_RESET = 'reset';

type SubAction = {
  type: typeof MOBILE_ACTION_SUB;
  payload: RenderFunc;
};

type BackAction = {
  type: typeof MOBILE_ACTION_BACK;
};

type ResetAction = {
  type: typeof MOBILE_ACTION_RESET;
};

type StateAction = SubAction | BackAction | ResetAction;

export type Props = NoChildrenProp<{
  items: TopMenuItems;
  offsetTop: number;
}>;

type OncoreFlexReducer = Reducer<State, StateAction>;

const OncoreFlexMenuMobile: React.FC<Props> = ({ items, offsetTop }) => {
  const appDispatch = useDispatch();
  const { isMenuOpen } = useSelector((s) => s.app);

  const [state, dispatch] = useReducer<OncoreFlexReducer>((previous, action) => {
    switch (action.type) {
      case MOBILE_ACTION_SUB: {
        return {
          ...previous,
          parents: previous.current ? [...previous.parents, previous.current] : previous.parents,
          current: action.payload,
          child: undefined,
          parentAnimation: subEffect,
          currentAnimation: subToChildEffect,
          childAnimation: undefined
        };
      }
      case MOBILE_ACTION_BACK: {
        return {
          ...previous,
          parents: previous.parents.slice(0, -1),
          current: previous.parents.slice(-1)[0],
          child: previous.current,
          parentAnimation: undefined,
          currentAnimation: backEffect,
          childAnimation: backToParentEffect
        };
      }
      case MOBILE_ACTION_RESET: {
        return {
          parents: []
        };
      }
      default: {
        return previous;
      }
    }
  }, {
    parents: []
  });

  useEffect(() => {
    if (isMenuOpen) {
      dispatch({
        type: MOBILE_ACTION_RESET
      });
    }
  }, [isMenuOpen, dispatch]);

  const goSub = (item: MenuItem & MenuItemChildren) => {
    dispatch({
      type: MOBILE_ACTION_SUB,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      payload: getSubRenderFunc(item, item.children)
    });
  };

  const goBack = () => {
    dispatch({
      type: MOBILE_ACTION_BACK
    });
  };

  const backButton = (
    <BackButton onClick={goBack}>
      <ArrowBack />
    </BackButton>
  );

  const renderItem = (item: TopMenuItem | SubMenuItem | DivMenuItem): React.ReactNode => (
    <div key={item.id}>
      {isTopMenuItem(item) && <OncoreFlexMenuMobileItem item={item} goSub={goSub} />}
      {isSubMenuItem(item) && <OncoreFlexMenuMobileItem item={item} goSub={goSub} />}
      {isDivMenuItem(item) && <OncoreFlexMenuDivider item={item} />}
    </div>
  );

  const getTopRenderFunc = (): RenderFunc => ((animation) => (
    <TopPane sx={{ animation: `${ animation } .2s forwards` }}>
      <OncoreFlexMenuMobileTenants goSub={goSub} />
      {items.map(renderItem)}
      <OncoreFlexMenuMobileProfile />
      <OncoreFlexMenuMobileLogout />
      <OncoreFlexMenuFill />
    </TopPane>
  ));

  const getSubRenderFunc = (root: MenuItem, children: SubMenuItems): RenderFunc => ((animation) => (
    <SubPane key={root.id} sx={{ animation: `${ animation } .2s forwards` }}>
      <OncoreFlexMenuMobileItem
        item={{
          id: 'back',
          type: MENU_ITEM_TYPE_TOP,
          contextType: null,
          icon: () => (backButton),
          title: root.title,
          onClick: goBack,
          products: null,
          children: [],
          spike: {},
        }}
        goSub={() => {}}
      />
      {children.map(renderItem)}
      <OncoreFlexMenuFill />
    </SubPane>
  ));

  const parent = state.parents.slice(-1)[0] ?? getTopRenderFunc();
  const current = state.current ?? getTopRenderFunc();
  const {
    child,
    parentAnimation,
    currentAnimation,
    childAnimation
  } = state;

  return (
    <Modal
      sx={{ top: offsetTop }}
      open={isMenuOpen}
      onClose={() => appDispatch(setMenuOpen(false))}
      hideBackdrop
    >
      <>
        {parent && parent(parentAnimation)}
        {current && current(currentAnimation)}
        {child && child(childAnimation)}
      </>
    </Modal>
  );
};

export default OncoreFlexMenuMobile;
