import { css as o, keyframes as m } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-C9VCECeM.js";
import { getSpacings as t } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-B6kdoens.js";
import "../../CssBaseline-CQMfs2gH.js";
const h = {
  "top-left": o`
    top: 0;
    left: 0;

    margin-top: ${t("xl")};
    margin-left: ${t("xl")};
  `,
  "bottom-left": o`
    bottom: 0;
    left: 0;

    margin-bottom: ${t("xl")};
    margin-left: ${t("xl")};
  `,
  "top-right": o`
    top: 0;
    right: 0;

    margin-top: ${t("xl")};
    margin-right: ${t("xl")};
  `,
  "bottom-right": o`
    bottom: 0;
    right: 0;

    margin-bottom: ${t("xl")};
    margin-right: ${t("xl")};
  `
}, i = m`
  0% {
    right: 100%;
  }
  
  100% {
    right: 0;
  }
`, r = m`
  0% {
    left: 100%;
  }
  
  100% {
    left: 0;
  }
`, s = {
  "top-left": i,
  "bottom-left": i,
  "top-right": r,
  "bottom-right": r
};
export {
  s as notificationItemAnimationKeyframes,
  h as notificationListStyles
};
