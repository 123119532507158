import {
  Box,
  Stack,
  Typography,
  Link,
  Switch,
  FormControlLabel,
  CircularProgress,
  Theme
} from '@mui/material';
import { useMutation } from '@oncore/shared';
import { terminology } from 'src/global';
import { Action, Store } from '../reducer';
import { MarkAllNotificationsAsReadMutation, markAllNotificationsAsReadMutation } from '../relay';
import environment from 'src/environment';

type Props = {
  state: Store;
  dispatch: React.Dispatch<Action>;
};

const styles = {
  container: {
    pt: 4,
    pb: 4,
    pl: 2,
    pr: 2,
  },
  markAll: {
    fontWeight: 500,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  header: {
    fontSize: '24px',
    fontWeight: '500'
  },
  count: (theme: Theme) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '12px',
    marginLeft: '10px',
    display: 'block',
    height: '20px',
    lineHeight: '20px',
    paddingLeft: '4px',
    paddingRight: '4px',
    minWidth: '20px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: '600',
    position: 'relative',
    top: '8px'
  }),
  unreadSwitch: {
    ml: 1
  },
  unreadLabel: {
    '& > .MuiFormControlLabel-label': {
      fontSize: '14px',
      fontWeight: '600'
    }
  }
};

const Header = (props: Props) => {
  const {
    state,
    dispatch
  } = props;

  const [markAllRead, readAllLoading] = useMutation<MarkAllNotificationsAsReadMutation>({
    graphql: markAllNotificationsAsReadMutation,
    environment,
    variables: {},
    silent: true,
    onComplete: (response) => {
      if (state.filter.statusOnlyUnread) {
        dispatch({
          type: 'REMOVE_ITEMS',
          payload: [...response.notification.map((x) => x.id)]
        });
      } else {
        dispatch({
          type: 'UPDATE_ITEMS',
          payload: [...response.notification]
        });
      }
    }

  });

  const handleMarkAllClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    markAllRead();
  };

  return (
    <Box sx={styles.container}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row">
          <Typography style={styles.header}>
            {terminology.notifications.title}
          </Typography>
          {state.totalCount ? (
            <Typography sx={styles.count}>{state.totalCount}</Typography>
          ) : null}
        </Stack>
        <FormControlLabel
          value="start"
          checked={state.filter.statusOnlyUnread}
          control={
            <Switch
              data-dd-action-name={terminology.notifications.filterUnread}
              color="primary"
              size="small"
              sx={styles.unreadSwitch}
              onChange={(e) => dispatch({
                type: 'RESET_FILTER',
                payload: { statusOnlyUnread: e.target.checked }
              })}
            />
          }
          label={terminology.notifications.filterUnread}
          labelPlacement="start"
          sx={styles.unreadLabel}
        />
      </Stack>
      {state.items.length > 0 && (
        <Stack
          alignItems="center"
          justifyContent="flex-end"
          direction="row"
          mt={3}
        >
          {readAllLoading && (
            <CircularProgress size={16} sx={{ mr: 1 }} />
          )}
          <Link
            onClick={handleMarkAllClick}
            sx={styles.markAll}
            variant="body2"
          >
            {terminology.notifications.markAll}
          </Link>
        </Stack>
      )}
    </Box>
  );
};

export default Header;