import { ReactNode } from 'react';

import Notification from '@oncore/ui/Notification';
import QueryMutationHandler from './QueryMutationHandler';

export type OncoreNotificationProps = {
  children: ReactNode;
};

const OncoreNotification = (props: OncoreNotificationProps) => {

  return (
    <Notification position="bottom-right">
      {props.children}
      <QueryMutationHandler/>
    </Notification>
  );
};

export default OncoreNotification;

