import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/state/store';

const getAppState = (state: RootState) => state.app;

export const getAppStateSelector = () => {
  return createSelector(getAppState, (appState) => appState);
};

export const getUserSelector = () => {
  return createSelector(getAppState, (appState) => {
    const {
      displayName,
      userID
    } = appState;

    return {
      displayName,
      userID
    };
  });
};

export const getEnterpriseContext = () => {
  return createSelector(getAppState, (appState) => {

    if (appState.currentContext.context === 'enterprise') {
      return appState.currentContext;
    }

    return undefined;
  });
};
