import { Box, useTheme } from '@mui/material';
import { NotificationOtherIcon, NotificationTimesheetIcon } from 'src/components/SvgIcons';
import { NotificationKind, NotificationStatus } from '../relay';

type Props = {
  status: NotificationStatus;
  kind: NotificationKind;
};

const styles = {
  iconContainer: {
    paddingTop: '12px',
    paddingRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: (color: string) => ({
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: color,
    '& > svg': {
      position: 'relative',
      left: '-3px',
      top: '-3px',
    }
  })
};

const NotificationIcon = (props: Props) => {
  const { status, kind } = props;

  const theme = useTheme();

  const color = status === 'unread' ? theme.palette.primary.main : theme.palette.grey[500];

  return (
    <Box sx={styles.icon(color)}>
      {kind === 'other' && (
        <NotificationOtherIcon color={color} />
      )}
      {(kind === 'timesheetEntry' || kind === 'timesheetApproved' || kind === 'timesheetRejected' || kind === 'timesheetReview') && (
        <NotificationTimesheetIcon color={color} />
      )}
    </Box>
  );
};

export default NotificationIcon;
