
export type Environment = {
  version: string;
  api: string;
  auth: {
    clientID: string;
    audience: string;
    domain: string;
    tenant: string;
  };
  amplitude: {
    key: string;
  };
  logs: {
    token: string;
  };
  rum: {
    appID: string;
    token: string;
  };
  lightboxJS: {
    key: string;
  };
  isReady?: boolean;
};

let environment: Environment | undefined;

export const fetchEnvironmentVariables = async () => {
  const isDev = import.meta.env.DEV;

  const response = await fetch(`/environment.${isDev ? 'dev.json' : 'json'}`);

  const variables: Environment = await response.json();

  return variables;
};

export const getEnvironmentVariables = async () => {
  environment = environment || await fetchEnvironmentVariables();

  return environment;
};
