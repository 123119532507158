/**
 * @generated SignedSource<<4d75860da72e91d90642fc96acf45709>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationKind = "other" | "timesheetApproved" | "timesheetEntry" | "timesheetRejected" | "timesheetReview";
export type NotificationStatus = "acknowledged" | "unread";
export type NotificationsMarkAllAsReadMutation$variables = {};
export type NotificationsMarkAllAsReadMutation$data = {
  readonly notification: ReadonlyArray<{
    readonly acknowledgedAt: string | null;
    readonly actionUrl: string;
    readonly createdAt: string | null;
    readonly id: string;
    readonly kind: NotificationKind;
    readonly status: NotificationStatus;
    readonly subject: string;
    readonly text: string;
  }>;
};
export type NotificationsMarkAllAsReadMutation = {
  response: NotificationsMarkAllAsReadMutation$data;
  variables: NotificationsMarkAllAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": "notification",
    "args": null,
    "concreteType": "Notification",
    "kind": "LinkedField",
    "name": "markAllNotificationsAsRead",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acknowledgedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actionUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "kind",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsMarkAllAsReadMutation",
    "selections": (v0/*: any*/),
    "type": "GeneralMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsMarkAllAsReadMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "95b804b8d03df537162285f1fe07c17d",
    "id": null,
    "metadata": {},
    "name": "NotificationsMarkAllAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationsMarkAllAsReadMutation {\n  notification: markAllNotificationsAsRead {\n    id\n    subject\n    status\n    text\n    acknowledgedAt\n    createdAt\n    actionUrl\n    kind\n  }\n}\n"
  }
};
})();

(node as any).hash = "f99f3fbef5277725eb008fb4507c5a21";

export default node;
