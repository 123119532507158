import { j as s } from "./jsx-runtime-B6kdoens.js";
import m from "styled-components";
import { c as d } from "./clsx.m-BMVuQnLY.js";
import { A as g } from "./Avatar-BuSTR0Yo.js";
import { t } from "./styles-DFJgZZAt.js";
import "./Typography-GJu-MHyZ.js";
import "./components/Theme/themes.es.js";
import "./palettes-C9VCECeM.js";
import { getPaletteText as a } from "./components/Theme/utils.es.js";
import "react";
import "./CssBaseline-CQMfs2gH.js";
const v = m.div`
  display: flex;
  align-items: center;
  
  text-align: left;
  
  > div.texts {
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
  }
  
  > div.texts span.text, > div.texts span.supportingText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div.texts span.text {
    color: ${a("secondary.default")};
  }

  > div.texts span.supportingText {
    color: ${a("tertiary.default")};
  }
  
  &.size-sm {
    gap: 10px;

    > div.texts span.text {
      ${t.variants.text.sm}
      ${t.weights.semibold}
    }

    > div.texts span.supportingText {
      ${t.variants.text.xs}
      ${t.weights.regular}
    }
  }
  
  &.size-md {
    gap: 12px;

    > div.texts span.text {
      ${t.variants.text.sm}
      ${t.weights.semibold}
    }

    > div.texts span.supportingText {
      ${t.variants.text.sm}
      ${t.weights.regular}
    }
  }

  &.size-lg {
    gap: 12px;

    > div.texts span.text {
      ${t.variants.text.md}
      ${t.weights.semibold}
    }

    > div.texts span.supportingText {
      ${t.variants.text.md}
      ${t.weights.regular}
    }
  }

  &.size-xl {
    gap: 16px;

    > div.texts span.text {
      ${t.variants.text.lg}
      ${t.weights.semibold}
    }

    > div.texts span.supportingText {
      ${t.variants.text.md}
      ${t.weights.regular}
    }
  }
`, c = {
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl"
}, N = (r) => {
  const {
    size: e = "sm",
    avatar: i,
    text: x,
    supportingText: p,
    className: o,
    ...n
  } = r, l = d(`size-${e}`, o);
  return /* @__PURE__ */ s.jsxs(v, { ...n, className: l, children: [
    /* @__PURE__ */ s.jsx(g, { ...i, size: i.size ?? c[e] }),
    /* @__PURE__ */ s.jsxs("div", { className: "texts", children: [
      /* @__PURE__ */ s.jsx("span", { className: "text", children: x }),
      /* @__PURE__ */ s.jsx("span", { className: "supportingText", children: p })
    ] })
  ] });
};
export {
  N as A
};
