type Node<T, > = {
  [P in keyof T]: (T[P] extends string
    ? string
    : T[P] extends ((...args: string[]) => string)
      ? T[P]
      : Node<T[P]>)
};

const Links = {
  home: '/',
  logout: '/logout',
  changeEmail: '/change-email',
  changePassword: '/change-password',
  enrollMfa: '/enroll-mfa',
  disableMfa: '/disable-mfa',
  user: {
    root: '/my',
    entry: '/my/entry',
    timesheets: '/my/timesheets',
    timesheet: {
      root: (timesheetID: string) => `/my/timesheets/${timesheetID}`,
      details: (timesheetID: string) => `/my/timesheets/${timesheetID}/details`,
      history: (timesheetID: string) => `/my/timesheets/${timesheetID}/history`,
    },
    payslips: '/my/payslips',
    invoices: '/my/invoices',
    profile: {
      security: '/profile/security',
      details: '/profile/details',
      tenants: '/profile/tenants',
      legalName: '/profile/change-legal-name',
      bankDetails: '/profile/bank-details',
      companyDetails: '/profile/company-details',
      view: '/profile'
    },
    expenses: '/my/expenses',
    expense: {
      new: '/my/expenses/new',
      view: (expenseID: string) => `/my/expenses/${expenseID}`
    }
  },
  agency: {
    root: (tenantID: string) => `/${tenantID}`,
    contracts: (tenantID: string) => `/${tenantID}/contracts`,
    admin: {
      users: (tenantID: string) => `/${tenantID}/admin/users`,
      user: {
        invite: (tenantID: string) => `/${tenantID}/admin/users/invite`,
        view: (tenantID: string, userID: string) => `/${tenantID}/admin/users/${userID}`,
        details: (tenantID: string, userID: string) => `/${tenantID}/admin/users/${userID}/details`
      },
      groups: (tenantID: string) => `/${tenantID}/admin/groups`,
      group: {
        new: (tenantID: string) => `/${tenantID}/admin/groups/new`,
        view: (tenantID: string, groupID: string) => `/${tenantID}/admin/groups/${groupID}`,
        details: (tenantID: string, groupID: string) => `/${tenantID}/admin/groups/${groupID}/details`
      }
    }
  },
  enterprise: {
    root: (tenantID: string) => `/${tenantID}`,
    positions: (tenantID: string) => `/${tenantID}/positions`,
    position: {
      new: (tenantID: string) => `/${tenantID}/positions/new`,
      root: (tenantID: string, positionID: string) => `/${tenantID}/positions/${positionID}`,
      details: (tenantID: string, positionID: string) => `/${tenantID}/positions/${positionID}/details`,
    },
    placement: {
      new: (tenantID: string) => `/${tenantID}/placements/new`
    },
    contracts: (tenantID: string) => `/${tenantID}/contracts`,
    contract: {
      view: (tenantID: string, contractID: string) => `/${tenantID}/contracts/${contractID}`,
      details: (tenantID: string, contractID: string) => `/${tenantID}/contracts/${contractID}/details`,
      approvers: (tenantID: string, contractID: string) => `/${tenantID}/contracts/${contractID}/approvers`
    },
    timesheets: (tenantID: string) => `/${tenantID}/timesheets`,
    timesheet: {
      view: (tenantID: string, timesheetID: string) => `/${tenantID}/timesheets/${timesheetID}`,
      details: (tenantID: string, timesheetID: string) => `/${tenantID}/timesheets/${timesheetID}/details`,
    },
    expenses: (tenantID: string) => `/${tenantID}/expenses`,
    expense: {
      view: (tenantID: string, expenseID: string) => `/${tenantID}/expenses/${expenseID}`
    },
    admin: {
      root: (tenantID: string) => `/${tenantID}/admin/users`,
      users: (tenantID: string) => `/${tenantID}/admin/users`,
      rateCards: (tenantID: string) => `/${tenantID}/admin/rate-cards`,
      user: {
        invite: (tenantID: string) => `/${tenantID}/admin/users/invite`,
        view: (tenantID: string, userID: string) => `/${tenantID}/admin/users/${userID}`,
        details: (tenantID: string, userID: string) => `/${tenantID}/admin/users/${userID}/details`
      },
      groups: (tenantID: string) => `/${tenantID}/admin/groups`,
      group: {
        new: (tenantID: string) => `/${tenantID}/admin/groups/new`,
        view: (tenantID: string, groupID: string) => `/${tenantID}/admin/groups/${groupID}`,
        details: (tenantID: string, groupID: string) => `/${tenantID}/admin/groups/${groupID}/details`
      },
      organisation: {
        root: (tenantID: string) => `/${tenantID}/admin/organisation`,
        structure: (tenantID: string) => `/${tenantID}/admin/organisation/structure`,
        settings: (tenantID: string) => `/${tenantID}/admin/organisation/settings`,
        new: (tenantID: string) => `/${tenantID}/admin/organisation/new`,
        view: (tenantID: string, unitID: string) => `/${tenantID}/admin/organisation/structure/${unitID}`
      },
      locations: {
        root: (tenantID: string) => `/${tenantID}/admin/locations`,
        structure: (tenantID: string) => `/${tenantID}/admin/locations/structure`,
        settings: (tenantID: string) => `/${tenantID}/admin/locations/settings`,
        new: (tenantID: string) => `/${tenantID}/admin/locations/new`,
        view: (tenantID: string, unitID: string) => `/${tenantID}/admin/locations/structure/${unitID}`
      },
      screenings: (tenantID: string) => `/${tenantID}/admin/screenings`
    }
  }
} as const;

const links: Node<typeof Links> = Links;

export default links;
