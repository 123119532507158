import { styled as muiStyled } from '@mui/system';
import { Box, Button } from '@mui/material';

export const TemplateContainer = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '96px 16px',
  [theme.breakpoints.down('sm')]: {
    padding: '64px 16px',
  },
}));

export const ActionButton = muiStyled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flex: 1,
  },
}));

export const ActionButtonContainer = muiStyled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  display: 'flex',
  gap: 12,
  justifyContent: 'center',
  width: '100%',
}));
