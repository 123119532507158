import {
  Box,
  Skeleton
} from '@mui/material';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flex: 1,
    mb: 2,
    pr: 3
  },
  iconContainer: {
    pl: '18px',
    pr: '18px',
    pt: '12px',
  },
  statusContainer: {
    pt: '28px',
    pl: '18px',
  },
  date: {
    fontSize: '12px',
    marginBottom: 1,
    width: '90px'
  },
  subject: {
    fontSize: '20px',
    marginBottom: 0.5,
    width: '60%'
  },
  content: {
    fontSize: 14
  }
};

const Notification = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.iconContainer}>
        <Skeleton variant="circular" width={40} height={40} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Skeleton variant="text" sx={styles.date} />
        <Skeleton variant="text" sx={styles.subject} />
        <Skeleton variant="text" sx={styles.content} />
        <Skeleton variant="text" sx={styles.content} width="95%" />
        <Skeleton variant="text" sx={styles.content} width="60%" />
      </Box>
      <Box sx={styles.statusContainer}>
        <Skeleton variant="circular" width={16} height={16} />
      </Box>
    </Box>
  );
};

export default Notification;
