import { useNavigate } from 'react-router-dom';
import { DropdownItem, DropdownItems } from '@oncore/ui/Dropdown';
import { links } from '../../components/chrome';

const ChromeDropdownItems = () => {
  const navigate = useNavigate();

  return (
    <>
      <DropdownItems>
        <DropdownItem
          leftIcon={{
            variant: 'user'
          }}
          text="View profile"
          onClick={() => navigate(links.user.profile.view)} />
      </DropdownItems>
      <DropdownItems>
        <DropdownItem
          leftIcon={{
            variant: 'log-out'
          }}
          text="Sign out"
          onClick={() => navigate(links.logout)} />
      </DropdownItems>
    </>
  );
};

export default ChromeDropdownItems;
