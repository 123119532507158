import React from 'react';
import { useNavigate } from 'react-router-dom';
import { People } from '@mui/icons-material';
import { useDispatch, useSelector } from 'src/hooks';
import { setMenuOpen } from 'src/store/appSlice';
import { NoChildrenProp } from '../common';
import {
  MENU_ITEM_TYPE_SUB,
  MENU_ITEM_TYPE_TOP,
  TopMenuItem,
  SubMenuItem,
} from './menu';
import OncoreFlexMenuMobileItem, { Props as OncoreFlexMenuMobileItemProps } from './OncoreFlexMenuMobileItem';
import links from './links';

export type Props = NoChildrenProp & Omit<OncoreFlexMenuMobileItemProps, 'item' | 'depth'>;

const OncoreFlexMenuMobileTenants: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contexts, userID, displayName } = useSelector((s) => s.app);

  const item: TopMenuItem = {
    contextType: null,
    id: 'switch-tenant',
    type: MENU_ITEM_TYPE_TOP,
    icon: People,
    title: 'Switch to',
    products: null,
    permissions: [],
    features: [],
    spike: {},
    children: contexts.map<SubMenuItem>((context) => {
      let link = '';

      switch (context.context) {
        case 'user':
          link = links.user.root;
          break;
        case 'enterprise':
          link = links.enterprise.root(context.id);
          break;
        case 'agency':
          link = links.agency.root(context.id);
          break;
      }

      return {
        type: MENU_ITEM_TYPE_SUB,
        id: context.context === 'user' ? userID! : context.id,
        title: context.context === 'user' ? displayName! : context.displayName,
        onClick: () => {
          dispatch(setMenuOpen(false));
          navigate(link);
        },
        products: null,
        children: [],
        permissions: [],
        features: [],
        spike: {
          to: link,
        },
      };
    })
  };

  return contexts.length > 1 ? (
    <OncoreFlexMenuMobileItem {...props} item={item} />
  ) : (<></>);
};

export default OncoreFlexMenuMobileTenants;
