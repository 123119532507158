const x = "0px 0px 0px 4px rgba(255, 130, 90, 0.24)", p = "0px 0px 0px 4px rgba(152, 162, 179, 0.14)", a = {
  focusRings: {
    brand: {
      default: x,
      xs: x,
      sm: `
        ${x},
        0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1)
      `
    },
    gray: {
      default: p,
      secondary: "0px 0px 0px 4px rgba(152, 162, 179, 0.2)",
      xs: `
        ${p},
        0px 1px 2px 0px rgba(16, 24, 40, 0.05)
      `,
      sm: `
        ${p},
        0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1)
      `
    },
    error: {
      default: "0px 0px 0px 4px rgba(240, 68, 56, 0.24)",
      xs: "0px 0px 0px 4px rgba(240, 68, 56, 0.24)"
    }
  },
  shadows: {
    xs: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    sm: `
      0px 1px 3px 0px rgba(16, 24, 40, 0.1),
      0px 1px 2px 0px rgba(16, 24, 40, 0.06)
    `,
    md: `
      0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06)
    `,
    lg: `
      0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03)
    `,
    xl: `
      0px 20px 24px -4px rgba(16, 24, 40, 0.08), 
      0px 8px 8px -4px rgba(16, 24, 40, 0.03)
    `,
    "2xl": "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
    "3xl": "0px 32px 64px -12px rgba(16, 24, 40, 0.14)"
  },
  blur: {
    sm: "8px",
    md: "16px",
    lg: "24px",
    xl: "40px"
  }
}, r = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: a
}, Symbol.toStringTag, { value: "Module" }));
export {
  a,
  r as e
};
