/**
 * @generated SignedSource<<ec181ed876c5047d594320c225a52fb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AgencyFeature = "contracts" | "reports";
export type AgencyPermission = "administrationUser" | "contractsUser" | "reportsCreate" | "reportsUser";
export type EnterpriseFeature = "contracts" | "costCentres" | "expenses" | "organisationalUnits" | "placements" | "positions" | "projects" | "rateCards" | "reports" | "simplexityHomepage" | "supplierPaymentsManagement" | "timesheetQuickReview" | "timesheets" | "upplft";
export type TenantPermission = "contractUser" | "expenseReview" | "expenseUser" | "placementCreate" | "placementUser" | "positionCreate" | "positionReview" | "positionSend" | "positionUser" | "reportCreate" | "reportUser" | "tenantAdmin" | "timesheetFinance" | "timesheetQuickReview" | "timesheetReview" | "timesheetUser";
export type UserFeature = "banking" | "companyDetails" | "expenses" | "invoices" | "materialUI" | "mfa" | "payslips" | "reports" | "timesheets" | "timesheetsTime" | "timesheetsWork" | "webNotifications";
export type UserPermission = "expensesCreateClientPaid" | "expensesCreatePackaged" | "expensesUser" | "invoicesUser" | "payslips" | "timesheets";
export type OncoreFlexContextQuery$variables = {};
export type OncoreFlexContextQuery$data = {
  readonly holidayBanner: {
    readonly endDate: string | null;
    readonly message: string;
    readonly startDate: string | null;
  } | null;
  readonly user: {
    readonly displayName: string;
    readonly features: ReadonlyArray<UserFeature>;
    readonly memberships: ReadonlyArray<{
      readonly agencyFeatures: ReadonlyArray<AgencyFeature>;
      readonly agencyMemberships: ReadonlyArray<{
        readonly group: {
          readonly displayName: string;
          readonly id: string;
        };
        readonly permissions: ReadonlyArray<AgencyPermission>;
      }>;
      readonly agencyPermissions: ReadonlyArray<AgencyPermission>;
      readonly displayName: string;
      readonly id: string;
      readonly type: "AgencyRoot";
    } | {
      readonly displayName: string;
      readonly enterpriseFeatures: ReadonlyArray<EnterpriseFeature>;
      readonly enterpriseMemberships: ReadonlyArray<{
        readonly group: {
          readonly displayName: string;
          readonly id: string;
        };
        readonly permissions: ReadonlyArray<TenantPermission>;
      }>;
      readonly enterprisePermissions: ReadonlyArray<TenantPermission>;
      readonly id: string;
      readonly type: "EnterpriseRoot";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly type: "%other";
    }>;
    readonly permissions: ReadonlyArray<UserPermission>;
    readonly profile: {
      readonly email: string | null;
      readonly firstName: string | null;
    };
  };
};
export type OncoreFlexContextQuery = {
  response: OncoreFlexContextQuery$data;
  variables: OncoreFlexContextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v3 = {
  "alias": "profile",
  "args": null,
  "concreteType": "UserProfileEx",
  "kind": "LinkedField",
  "name": "profileEx",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AltGroup",
    "kind": "LinkedField",
    "name": "group",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "storageKey": null
  },
  (v2/*: any*/)
],
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    (v0/*: any*/),
    (v5/*: any*/),
    {
      "alias": "agencyFeatures",
      "args": null,
      "kind": "ScalarField",
      "name": "features",
      "storageKey": null
    },
    {
      "alias": "agencyPermissions",
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": "agencyMemberships",
      "args": null,
      "concreteType": "AgencyGroupMembership",
      "kind": "LinkedField",
      "name": "memberships",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "type": "AgencyRoot",
  "abstractKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    (v0/*: any*/),
    (v5/*: any*/),
    {
      "alias": "enterpriseFeatures",
      "args": null,
      "kind": "ScalarField",
      "name": "features",
      "storageKey": null
    },
    {
      "alias": "enterprisePermissions",
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": "enterpriseMemberships",
      "args": null,
      "concreteType": "EnterpriseGroupMembership",
      "kind": "LinkedField",
      "name": "memberships",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "type": "EnterpriseRoot",
  "abstractKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "HolidayBanner",
  "kind": "LinkedField",
  "name": "holidayBanner",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OncoreFlexContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRoot",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "memberships",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ],
    "type": "GeneralQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OncoreFlexContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRoot",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "memberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "9f86971515c32d9f04def4986679b01d",
    "id": null,
    "metadata": {},
    "name": "OncoreFlexContextQuery",
    "operationKind": "query",
    "text": "query OncoreFlexContextQuery {\n  user {\n    displayName\n    features\n    permissions\n    profile: profileEx {\n      email\n      firstName\n    }\n    memberships {\n      __typename\n      ... on AgencyRoot {\n        id\n        displayName\n        type: __typename\n        agencyFeatures: features\n        agencyPermissions: permissions\n        agencyMemberships: memberships {\n          group {\n            id\n            displayName\n          }\n          permissions\n        }\n      }\n      ... on EnterpriseRoot {\n        id\n        displayName\n        type: __typename\n        enterpriseFeatures: features\n        enterprisePermissions: permissions\n        enterpriseMemberships: memberships {\n          group {\n            id\n            displayName\n          }\n          permissions\n        }\n      }\n    }\n  }\n  holidayBanner {\n    message\n    startDate\n    endDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "575e917b862cf33f0c5f08d6be12ae72";

export default node;
