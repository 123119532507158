import { createContext as C, useContext as m, useState as v, useMemo as q } from "react";
const h = C({
  enqueueNotification: () => {
  },
  removeAllNotifications: () => {
  }
}), P = () => m(h), b = (u) => {
  const { autoHideDuration: f } = u, [
    n,
    a
  ] = v({}), s = q(() => Object.values(n).sort((t, o) => t.createdAt < o.createdAt ? -1 : t.createdAt > o.createdAt ? 1 : 0), [n]);
  return {
    notificationList: s,
    enqueueNotification: (t) => {
      const o = Date.now(), {
        onClose: c,
        id: e = o,
        ...d
      } = t, l = (i) => {
        a((A) => {
          const r = {
            ...A
          };
          return delete r[i], r;
        });
      }, N = () => {
        c && c(), l(e);
      };
      a((i) => ({
        ...i,
        [e]: {
          autoCloseDuration: f,
          ...d,
          id: e,
          createdAt: o,
          onClose: N
        }
      }));
    },
    removeAllNotifications: () => {
      s.forEach(({ onClose: t }) => {
        t && t();
      });
    }
  };
};
export {
  h as N,
  P as a,
  b as u
};
