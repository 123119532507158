import React from 'react';
import { Box, Drawer, useMediaQuery, useTheme, styled } from '@mui/material';

import { useSelector } from 'src/hooks';
import { NoChildrenProp } from '../common';
import { isDivMenuItem, TopMenuItems } from './menu';
import OncoreFlexMenuLogo from './OncoreFlexMenuLogo';
import OncoreFlexMenuFill from './OncoreFlexMenuFill';
import OncoreFlexMenuDivider from './OncoreFlexMenuDivider';
import OncoreFlexMenuDesktopItem from './OncoreFlexMenuDesktopItem';

type StyledDrawerProps = {
  offset: number;
};

const StyledDrawer = styled(Drawer)<StyledDrawerProps>(({ theme, offset }) => ({
  '& .MuiPaper-root': {
    border: 'none',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: 256,
    top: offset,
    height: `calc(100% - ${offset}px)`,
    '& ul': {
      listStyleType: 'none',
    }
  },
}));

export type Props = NoChildrenProp<{
  items: TopMenuItems;
  open: boolean;
  offsetTop: number;
}>;

const MenuDesktop: React.FC<Props> = ({ items, open, offsetTop }) => (
  <Box displayPrint="none">
    <StyledDrawer anchor="left" variant="persistent" open={open} offset={offsetTop}>
      <OncoreFlexMenuLogo />
      <ul>
        {items.map((item) => isDivMenuItem(item)
          ? <OncoreFlexMenuDivider key={item.id} item={item} />
          : <OncoreFlexMenuDesktopItem key={item.id} item={item} />)}
      </ul>
      <OncoreFlexMenuFill />
    </StyledDrawer>
  </Box>
);

const MenuContainer = (props: Omit<Props, 'open'>) => {
  const { isMenuOpen } = useSelector((s) => s.app);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return <MenuDesktop {...props} open={isDesktop || isMenuOpen}/>;
};

export default MenuContainer;
