import React from 'react';
import { RouteObject } from 'react-router-dom';
import {
  AgencyFeature,
  AgencyPermission,
  RouterRedirect,
} from '@oncore/shared';
import { AgencyContext } from 'src/store/appSlice';
import { links } from 'src/components/chrome';
import { childRouteFor, innerChildRouteFor, layoutFor } from './helpers';
import TenantContextSwitch from './TenantContextSwitch';
import AppLoader from 'src/spike/AppLoader';

const AgencyContracts = React.lazy(() => import('src/pages/agency/Contracts'));
const AgencyAdminUsers = React.lazy(() => import('src/pages/agency/admin/Users'));
const AgencyAdminUserInvite = React.lazy(() => import('src/pages/agency/admin/UserNew'));
const AgencyAdminUserRoot = React.lazy(() => import('src/pages/agency/admin/User/User'));
const AgencyAdminUserRedirect = React.lazy(() => import('src/pages/agency/admin/User/Redirect'));
const AgencyAdminUserDetails = React.lazy(() => import('src/pages/agency/admin/User/Details'));
const AgencyAdminGroups = React.lazy(() => import('src/pages/agency/admin/Groups'));
const AgencyAdminGroupNew = React.lazy(() => import('src/pages/agency/admin/GroupNew'));
const AgencyAdminGroupRoot = React.lazy(() => import('src/pages/agency/admin/Group/Group'));
const AgencyAdminGroupRedirect = React.lazy(() => import('src/pages/agency/admin/Group/Redirect'));
const AgencyAdminGroupDetails = React.lazy(() => import('src/pages/agency/admin/Group/Details'));

const NotFoundView = React.lazy(() => import('src/spike/Notice/NotFoundErrorNotice'));

export const getAgencyRoutes = (context: AgencyContext): RouteObject => {
  const features = context.features;
  const permissions = [
    ...context.permissions,
    ...context.groups.reduce((x, a) => [...x, ...a.permissions], [] as AgencyPermission[])
  ].filter((value, index, self) => self.indexOf(value) === index);

  const featContracts = features.includes(AgencyFeature.Contracts);

  const permAdministrationUser = permissions.includes(AgencyPermission.AdministrationUser);
  const permContactsUser = permissions.includes(AgencyPermission.ContractsUser);

  const children: RouteObject[] = [];

  let defaultPage: string | undefined = undefined;

  if (featContracts && permContactsUser) {
    defaultPage = links.agency.contracts(context.id);
    children.push(childRouteFor('contracts', <AgencyContracts />));
  }

  if (permAdministrationUser) {
    defaultPage = defaultPage || links.agency.admin.users(context.id);
    children.push(childRouteFor('admin/users', <AgencyAdminUsers />));
    children.push(childRouteFor('admin/users/invite', <AgencyAdminUserInvite />));
    children.push(childRouteFor('admin/users/:userID', <AgencyAdminUserRedirect />, [
      layoutFor(<AgencyAdminUserRoot />, [
        innerChildRouteFor('details', <AgencyAdminUserDetails />)
      ])
    ]));
    children.push(childRouteFor('admin/groups', <AgencyAdminGroups />));
    children.push(childRouteFor('admin/groups/new', <AgencyAdminGroupNew />));
    children.push(childRouteFor('admin/groups/:groupID', <AgencyAdminGroupRedirect />, [
      layoutFor(<AgencyAdminGroupRoot />, [
        innerChildRouteFor('details', <AgencyAdminGroupDetails />)
      ])
    ]));
  }

  children.push({
    path: '*',
    element: (
      <React.Suspense fallback={<AppLoader />}>
        <NotFoundView />
      </React.Suspense>
    )
  });

  if (defaultPage) {
    children.push({
      index: true,
      element: (<RouterRedirect to={defaultPage} />)
    });
  }

  return ({
    path: ':tenantID',
    children: [{
      element: (<TenantContextSwitch />),
      children
    }]
  });
};
