import { Fragment } from 'react';

import GlobalStyles from 'src/components/GlobalStyles';
import { withUntitledUIRenderer } from '../UntitledUIRenderer';


export default withUntitledUIRenderer({
  legacy: GlobalStyles,
  untitledUI: Fragment,
});
