import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';

type UserState = {
  currentUser: {
    token: string;
  }
};

export const setTokenRoutine = createRoutine('SET_TOKEN', {
  trigger: (tokenPayload: string) => tokenPayload,
  request: (tokenPayload: string) => tokenPayload,
});

const initialState: UserState = {
  currentUser: {
    token: '',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setTokenRoutine.REQUEST,
      (state, action: PayloadAction<string>) => {
        state.currentUser.token = action.payload;
      }
    ).addDefaultCase(() => {});
  },
});

export default userSlice.reducer;
