import React from 'react';
import useScrollReset from 'src/hooks/useScrollReset';

const ScrollReset = (): React.ReactElement | null => {
  useScrollReset();

  return null;
};

export default ScrollReset;
