import SideBar from '@oncore/ui/SideBar';
import {
  useMenuList,
} from 'src/components/chrome/hooks';
import ChromeSideBarItem from './ChromeSideBarItem';
import ChromeSideBarParentItem from './ChromeSideBarParentItem';
import { useChromeProductName } from './hooks';
import WorkspaceSwitcher from '../WorkspaceSwitcher';

const ChromeSideBar = () => {
  const menuList = useMenuList();
  const logo = useChromeProductName();
  const footer = (
    <WorkspaceSwitcher/>
  );

  return (
    <SideBar
      logo={logo}
      footer={footer}>
      {
        menuList.map((menuItem) => {

          if (menuItem.type !== 'top') {
            return null;
          }

          if ('children' in menuItem) {

            return (
              <ChromeSideBarParentItem
                key={menuItem.id}
                menuItem={menuItem}
              />
            );
          }

          return (
            <ChromeSideBarItem
              key={menuItem.id}
              menuItem={menuItem}
            />
          );
        })
      }
    </SideBar>
  );
};

export default ChromeSideBar;
