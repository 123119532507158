import moment, { Moment } from 'moment';
import { ChunkingLoaderAction, isExhaustive } from '@oncore/shared';
import {
  NotificationsInitialQuery,
  NotificationStatus,
  NotificationsFurtherQuery,
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsUnreadMutation,
  NotificationKind
} from './relay';

export type Reference = {
};

export type Notification = {
  id: string;
  subject: string;
  text: string;
  status: NotificationStatus;
  acknowledgedAt: Moment | null;
  createdAt: Moment | null;
  actionUrl: string;
  kind: NotificationKind;
};

export type NotificationData =
  | NotificationsInitialQuery['response']['user']['notifications']['items'][0]
  | NotificationsFurtherQuery['response']['user']['notifications']['items'][0]
  | MarkNotificationsAsReadMutation['response']['notification']
  | MarkNotificationsAsUnreadMutation['response']['notification'];

export type InitialData = NotificationsInitialQuery['response']['user']['notifications'];

export type FurtherData = NotificationsFurtherQuery['response']['user']['notifications'];

export type Filter = {
  statusOnlyUnread: boolean
};

export type Action = ChunkingLoaderAction<Filter, InitialData, FurtherData>
| {
  type: 'UPDATE_ITEM';
  payload: NotificationData;
} | {
  type: 'UPDATE_ITEMS';
  payload: NotificationData[];
} | {
  type: 'RECEIVE_ITEM';
  payload: NotificationData;
} | {
  type: 'REMOVE_ITEM';
  payload: NotificationData;
} | {
  type: 'REMOVE_ITEMS';
  payload: string[];
} | {
  type: 'OPEN_DROPDOWN';
} | {
  type: 'CLOSE_DROPDOWN';
} | {
  type: 'UPDATE_PERMISSION';
  payload: NotificationPermission;
};

export type Store = {
  loaded: boolean;
  opened: boolean;
  attn: boolean;
  filter: Filter;
  items: Notification[];
  continuationToken: string | null;
  permission?: NotificationPermission;
  totalCount: number | null;
};

export const initialState: Store = {
  loaded: false,
  opened: false,
  attn: false,
  filter: {
    statusOnlyUnread: true
  },
  items: [],
  continuationToken: null,
  totalCount: null
};

function convert(input: NotificationData): Notification {
  return <Notification>({
    id: input.id,
    status: input.status,
    subject: input.subject,
    text: input.text,
    acknowledgedAt: input.acknowledgedAt ? moment(input.acknowledgedAt) : null,
    createdAt: input.createdAt ? moment(input.createdAt) : null,
    actionUrl: input.actionUrl,
    kind: input.kind,
  });
}

export function reducer(prevState: Store, action: Action): Store {
  try {
    switch (action.type) {
      case 'RESET_FILTER':
        return {
          ...prevState,
          loaded: false,
          filter: action.payload,
          items: [],
          totalCount: null
        };
      case 'RESET_DATA':
        return {
          ...prevState,
          loaded: true,
          continuationToken: action.payload.continuationToken,
          attn: !!action.payload.items.find((i) => i.status === 'unread'),
          items: action.payload.items.map(convert),
          totalCount: action.payload.totalCount
        };
      case 'APPEND_DATA':
        return {
          ...prevState,
          loaded: true,
          continuationToken: action.payload.continuationToken,
          attn: !!prevState.items.find((i) => i.status === 'unread') || !!action.payload.items.find((i) => i.status === 'unread'),
          items: [
            ...prevState.items,
            ...action.payload.items.map(convert)
          ],
        };
      case 'UPDATE_ITEM':
        return {
          ...prevState,
          loaded: true,
          attn: !!prevState.items.find((i) => i.id !== action.payload.id && i.status === 'unread') || action.payload.status === 'unread',
          items: prevState.items.map((x) => x.id === action.payload.id ? convert(action.payload) : x)
        };
      case 'UPDATE_ITEMS':
        return {
          ...prevState,
          loaded: true,
          attn:
            !!prevState.items.find((x) => action.payload.find((u) => u.id === x.id) ? false : x.status === 'unread') ||
            !!action.payload.find((x) => x.status === 'unread'),
          items: prevState.items.map((x) => {
            const updateItem = action.payload.find((u) => u.id === x.id);
            return updateItem ? convert(updateItem) : x;
          })
        };
      case 'RECEIVE_ITEM':
        return {
          ...prevState,
          loaded: true,
          attn: true,
          items: [convert(action.payload), ...prevState.items],
          totalCount: prevState.totalCount ? prevState.totalCount + 1 : null,
        };
      case 'REMOVE_ITEM':
        return {
          ...prevState,
          loaded: true,
          items: prevState.items.filter((x) => x.id !== action.payload.id),
          totalCount: prevState.totalCount ? prevState.totalCount - 1 : null,
        };
      case 'REMOVE_ITEMS':
        return {
          ...prevState,
          loaded: true,
          items: prevState.items.filter((x) => !action.payload.includes(x.id)),
          totalCount: prevState.totalCount ? prevState.totalCount - action.payload.length : null,
        };
      case 'OPEN_DROPDOWN':
        return {
          ...prevState,
          opened: true,
          // attn: false
        };
      case 'CLOSE_DROPDOWN':
        return {
          ...prevState,
          opened: false,
          // attn: false
        };
      case 'UPDATE_PERMISSION':
        return {
          ...prevState,
          permission: action.payload
        };
      default:
        isExhaustive(action);
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}
