import { AgencyContext, EnterpriseContext, UserContext } from 'src/store/appSlice';
import { useSelector } from './useStore';

export const useEnterpriseContext = (): EnterpriseContext => {
  const { currentContext } = useSelector((s) => s.app);

  if (currentContext.context !== 'enterprise') {
    throw new Error('wrong current context');
  }

  return currentContext;
};

export const useAgencyContext = (): AgencyContext => {
  const { currentContext } = useSelector((s) => s.app);

  if (currentContext.context !== 'agency') {
    throw new Error('wrong current context');
  }

  return currentContext;
};

export const useUserContext = (): UserContext => {
  const { currentContext } = useSelector((s) => s.app);

  if (currentContext.context !== 'user') {
    throw new Error('wrong current context');
  }

  return currentContext;
};